import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ServicePreferences = ({ services, handleCheckboxChange }) => {
  const serviceItems = [
    "Compassionate Presence",
    "Hindu Tradition-related Matters",
    "Hindu Identity",
    "Yoga Therapy",
    "Meditation",
    "Art Therapy",
    "Senior",
    "Cancer Support",
    "Palliative and Hospice Care",
    "Trauma and Aged Care",
  ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" sx={{ fontFamily: "Arial, sans-serif" }}>
          Service Preferences
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
        }}
      >
        {serviceItems.map((service) => (
          <label key={service} className="service-label">
            <input
              type="checkbox"
              className="service-checkbox"
              checked={services[service]}
              onChange={() => handleCheckboxChange(service)}
            />
            {service}
          </label>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ServicePreferences;
