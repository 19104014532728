import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getSCPByPhone, getSCPById } from "../controllers/scp";
import { useUser } from "../contexts/userContext";
import moment from "moment";
import { supabase } from "../utils/supabase";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import axios from "axios";

const Invitation = () => {
  const [scpDetails, setScpDetails] = useState(null);
  const [meetingDate, setMeetingDate] = useState(null);
  const [meetingTime, setMeetingTime] = useState(null);
  const [meetingDateInLocalTz, setMeetingDateInLocalTz] = useState(null);
  const [meetingTimeInLocalTz, setMeetingTimeInLocalTz] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();
  const { user, setUser } = useUser();

  const verifyUser = async () => {
    const params = queryString.parse(location.search);

    if (params.id) {
      const columnName = "personal_link";
      const { data, error } = await supabase
        .from("pending_meet_me")
        .select(`id, scp_id, meeting_date, meeting_time, ${columnName}`);

      if (error) {
        console.error("Error fetching data from Supabase:", error);
      } else if (data && data.length > 0) {
        const row = data.find(
          (row) => row[columnName] && row[columnName][params.id]
        );

        if (row) {
          setRowId(row.id);
          setMeetingDate(row.meeting_date);
          setMeetingTime(row.meeting_time);
          getSCPByPhone(row[columnName][params.id])
            .then((res) => {
              setUser(res);
              return getSCPById(row.scp_id);
            })
            .then((res) => {
              setScpDetails(res);
            });
        } else {
          console.error("Key not found in any row's JSON object");
          setLinkExpired(true);
        }
      }
    }

    if (params.redirectUrl) {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=19252305898&text=Sampark%20for%20ID%20" +
        params.redirectUrl +
        "&type=phone_number&app_absent=0";
    }
  };

  function calculateEndTime(startTime) {
    const [hours, minutes] = startTime.split(":").map(Number);
    let endHours = hours + 1;
    let endMinutes = minutes;

    if (endHours >= 24) {
      endHours = 0;
    }

    const formattedEndHours = String(endHours).padStart(2, "0");
    const formattedEndMinutes = String(endMinutes).padStart(2, "0");

    return `${formattedEndHours}:${formattedEndMinutes}`;
  }

  const handleAccept = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    let dataJson = {
      scp_id: scpDetails.id,
      scp_name: scpDetails.name,
      scp_phone: scpDetails.whatsapp_phone_no,
      scp_timezone: scpDetails.timezone,
      patient_id: user.id,
      patient_name: user.name,
      patient_phone: user.whatsapp_phone_no,
      patient_timezone: user.timezone,
      meeting_date: meetingDate,
      start_time: meetingTime,
      end_time: calculateEndTime(meetingTime),
    };

    try {
      const response = await axios.post(
        "https://backend.trayaschedule.hsciglobal.org/create-personal-meeting",
        dataJson
      );

      // Set the personal_link to NULL after successful meeting creation
      const { error } = await supabase
        .from("pending_meet_me")
        .update({ personal_link: null })
        .eq("id", rowId);

      if (error) {
        console.error("Error updating personal_link:", error);
      } else {
        setSuccessMessage(
          "Meeting successfully created! We will send you the meeting details along with the invite link on Whatsapp."
        );
      }
    } catch (error) {
      console.error("Error creating meeting:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    setLoading(true);

    try {
      const { error } = await supabase
        .from("pending_meet_me")
        .update({ personal_link: null })
        .eq("id", rowId);

      if (error) {
        console.error("Error updating personal_link:", error);
      } else {
        setSuccessMessage("You have successfully declined the invitation.");
      }
    } catch (error) {
      console.error("Error during decline operation:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyUser();
  }, [location, setUser]);

  useEffect(() => {
    if (meetingDate && meetingTime && scpDetails) {
      const meetingDateUTC = moment.utc(
        `${meetingDate}T${meetingTime.split("-")[0]}`
      );
      const meetingDateUserTZ = meetingDateUTC.clone().tz(user.timezone);
      const formattedDate = meetingDateUserTZ.format("D MMMM YYYY");
      const formattedTime = meetingDateUserTZ.format("h:mm A");

      setMeetingDateInLocalTz(formattedDate);
      setMeetingTimeInLocalTz(formattedTime);
    }
  }, [meetingDate, meetingTime, scpDetails]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f4f4f9",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <CircularProgress />
        <Typography
          variant="h6"
          sx={{ marginTop: "20px", textAlign: "center", color: "#555" }}
        >
          Please wait...
        </Typography>
      </Box>
    );
  }

  if (successMessage) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f4f4f9",
          padding: "20px",
          boxSizing: "border-box",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333",
            textAlign: "center",
          }}
        >
          {successMessage}
        </span>
      </div>
    );
  }

  return !linkExpired && user && scpDetails ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f7f7fa",
        padding: "40px",
        boxSizing: "border-box",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          marginBottom: "30px",
          color: "#333",
        }}
      >
        Welcome, {user.name} Ji
      </Typography>
      <Box
        sx={{
          padding: "30px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          maxWidth: "600px",
          textAlign: "center",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            marginBottom: "20px",
            fontSize: "18px",
            lineHeight: "1.6",
            color: "#444",
          }}
        >
          <strong>{scpDetails.name}</strong> is inviting you to a scheduled
          meeting on <strong>{meetingDateInLocalTz}</strong> at{" "}
          <strong>{meetingTimeInLocalTz}</strong> ({user.timezone})
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleAccept}
            color="primary"
            sx={{
              padding: "10px 30px",
              fontSize: "16px",
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              ":hover": {
                backgroundColor: "#1565c0",
              },
            }}
          >
            Accept
          </Button>
          <Button
            variant="outlined"
            onClick={handleDecline}
            color="secondary"
            sx={{
              padding: "10px 30px",
              fontSize: "16px",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              ":hover": {
                borderColor: "#1565c0",
                color: "#1565c0",
              },
            }}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f4f4f9",
        color: "#333",
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <CircularProgress />
      <Typography
        variant="h6"
        sx={{ marginTop: "20px", textAlign: "center", color: "#555" }}
      >
        Loading...
      </Typography>
    </Box>
  );
};

export default Invitation;
