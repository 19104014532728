import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import SearchAllAvailability from "./analytics/SearchAllAvailability";

const BookAllAvailability = ({
  student,
  userRole,
  setIsSuccess,
  scholar = false,
}) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());

  const timezones = moment.tz.names();

  const handleTimeZoneChange = (e) => {
    e.preventDefault();
    setSelectedTimeZone(e.target.value);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <div
        style={{
          marginBottom: "10px",
          padding: "10px",
        }}
      >
        <select
          id="timezone-select"
          value={selectedTimeZone}
          onChange={handleTimeZoneChange}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            fontSize: "14px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            transition: "border-color 0.3s ease-in-out",
          }}
          onMouseOver={(e) => (e.target.style.borderColor = "#888")}
          onMouseOut={(e) => (e.target.style.borderColor = "#ccc")}
          onFocus={(e) => (e.target.style.borderColor = "#888")}
          onBlur={(e) => (e.target.style.borderColor = "#ccc")}
        >
          {timezones.map((tz) => (
            <option
              key={tz}
              value={tz}
              style={{
                fontFamily: "Arial, sans-serif",
              }}
            >
              {tz}
            </option>
          ))}
        </select>
      </div>
      <Typography variant="h6" gutterBottom>
        Search Availability
      </Typography>
      <SearchAllAvailability
        selectedTimeZone={selectedTimeZone}
        student={student}
        setIsSuccess={setIsSuccess}
        userRole={userRole}
        scholar={scholar}
      />
    </Box>
  );
};

export default BookAllAvailability;
