import React, { useState, useEffect } from "react";
import {
  getScpAvailabilityToday,
  getScpAvailabilityThisWeek,
  getScpAvailabilityThisMonth,
} from "../../controllers/analytics";
import {
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  MenuItem,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { getAllSCPs } from "../../controllers/scp";
import moment from "moment";
import { DateTime } from "luxon";
import axios from "axios";
import { supabase } from "../../utils/supabase";
import { format, parse } from "date-fns";

const SearchAllAvailability = ({
  selectedTimeZone,
  student,
  setIsSuccess,
  userRole,
  scholar,
}) => {
  const [timeRange, setTimeRange] = useState("week");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getAllSCPs().then((res) => {
      const usersArray = res
        .map((user) => ({
          id: user.id,
          name: user.name,
          phone: user.whatsapp_phone_no,
          timezone: user.timezone,
        }))
        .filter(
          (user) =>
            !scholar ||
            user.id === 28 ||
            user.id === 34 ||
            user.id === 41 ||
            user.id === 51
        );
      setUsers(usersArray);
    });
  }, [scholar]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const handleUserChange = (event) => {
    event.preventDefault();
    let userDetails = event.target.value;

    setSelectedUserDetails(userDetails);
    setSelectedUser(userDetails.id);
  };

  useEffect(() => {
    if (!selectedUser) {
      return;
    } else {
      async function fetchData() {
        let data = [];

        switch (timeRange) {
          case "today":
            data = await getScpAvailabilityToday(
              selectedUser,
              selectedTimeZone
            );
            break;
          case "week":
            data = await getScpAvailabilityThisWeek(
              selectedUser,
              selectedTimeZone
            );
            break;
          case "month":
            data = await getScpAvailabilityThisMonth(
              selectedUser,
              selectedTimeZone
            );
            break;
          default:
            break;
        }

        // return modifiedData;
        const modifiedData = data.map((item) => {
          // Parse date and time components
          const [year, month, day] = item.date.split("-").map(Number);
          const [startHour, startMinute] = item.start_time
            .split(":")
            .map(Number);
          const [endHour, endMinute] = item.end_time.split(":").map(Number);

          // Create start datetime in UTC
          const startDateTime = new Date(
            Date.UTC(year, month - 1, day, startHour, startMinute)
          );

          // Create end datetime in UTC
          let endDateTime = new Date(
            Date.UTC(year, month - 1, day, endHour, endMinute)
          );

          // Check if end time is earlier than start time, indicating spillover to next day
          if (endDateTime < startDateTime) {
            // If end time is before start time, it means it's on the next day
            endDateTime = new Date(
              Date.UTC(year, month - 1, day + 1, endHour, endMinute)
            );
          }

          // Format the modified item
          return {
            id: item.id,
            scp_id: item.scp_id,
            date: item.date,
            start_time: startDateTime.toISOString(),
            end_time: endDateTime.toISOString(),
            created_at: item.created_at,
            scp: item.scp,
          };
        });

        return modifiedData;
      }

      async function convertTimezones(requestBody) {
        try {
          const response = await fetch(
            "https://backend.trayaschedule.hsciglobal.org/convert-timezone",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );
          return await response.json();
        } catch (error) {
          console.error("Error:", error);
          return null;
        }
      }

      async function processData() {
        setIsLoading(true);
        try {
          const modData = await fetchData();

          const requestBody = {
            times: modData.map((item) => ({
              id: item.id,
              start_time: item.start_time,
              end_time: item.end_time,
            })),
            currentTimeZone: "UTC",
            targetTimeZone: selectedTimeZone,
          };

          const convertedTimes = await convertTimezones(requestBody);

          if (convertedTimes) {
            // Get current date and time in user's timezone
            const userNow = new Date().toLocaleString("en-US", {
              timeZone: selectedTimeZone,
            });
            const now = new Date(userNow);

            // Get just the date part for today in user's timezone
            const todayDate = new Date(now).setHours(0, 0, 0, 0);

            const updatedData = modData.map((item) => {
              const convertedItem = convertedTimes.find(
                (ct) => ct.id === item.id
              );
              if (convertedItem) {
                return {
                  ...item,
                  converted_start_time: convertedItem.start_time,
                  converted_end_time: convertedItem.end_time,
                };
              }
              return item;
            });

            // Filter availabilities
            const filteredData = updatedData.filter((availability) => {
              const availabilityDateTime = new Date(
                availability.converted_start_time.convertedDate
              );
              const availabilityDate = new Date(availabilityDateTime).setHours(
                0,
                0,
                0,
                0
              );

              // If it's a future date, include it
              if (availabilityDate > todayDate) {
                return timeRange === "today" ? false : true;
              }

              // If it's today, check the time
              if (availabilityDate === todayDate) {
                return availabilityDateTime >= now;
              }

              return false;
            });

            let finalData = filteredData.sort((a, b) => {
              const dateA = new Date(a.converted_start_time.convertedDate);
              const dateB = new Date(b.converted_start_time.convertedDate);
              return dateA - dateB;
            });

            setAvailabilities(finalData);
          }
        } catch (error) {
          console.error("Error processing data:", error);
        } finally {
          setIsLoading(false);
        }
      }

      processData();
    }
  }, [selectedTimeZone, timeRange, selectedUser]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSubmitting(false);
    setSelectedAvailability(null);
    setTimeSlots([]);
    setSelectedSlot("");
  };

  const handleSlotSelection = (event) => {
    setSelectedSlot(event.target.value);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MMMM Do, YYYY");
  };

  const formatTimeToUTC = (timeString, dateString, tz) => {
    const dateTimeString = `${dateString}T${timeString}:00`;

    const localDateTime = DateTime.fromISO(dateTimeString, { zone: tz });
    const utcDateTime = localDateTime.setZone("UTC");

    return utcDateTime.toFormat("HH:mm");
  };

  const generateTimeSlots = (startTime, endTime) => {
    let start = moment(startTime, "HH:mm");
    let end = moment(endTime, "HH:mm");
    let slots = [];

    // If end time is before start time, assume it's on the next day
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    while (start.isBefore(end)) {
      let nextSlot = moment(start).add(30, "minutes");
      if (nextSlot.isAfter(end)) nextSlot = moment(end);
      slots.push(`${start.format("HH:mm")} - ${nextSlot.format("HH:mm")}`);
      start.add(30, "minutes");
    }
    return slots;
  };

  const handleBookClick = (availabilityRow, availability) => {
    setSelectedAvailability(availabilityRow);
    const slots = generateTimeSlots(
      availability.split("-")[0],
      availability.split("-")[1]
    );
    setTimeSlots(slots);
    setOpenDialog(true);
  };

  const handleConfirmBooking = async () => {
    if (selectedSlot) {
      setIsSubmitting(true);

      let startTime = formatTimeToUTC(
        selectedSlot.split(" - ")[0],
        selectedAvailability.converted_start_time.date,
        selectedTimeZone
      );
      let endTime = formatTimeToUTC(
        selectedSlot.split(" - ")[1],
        selectedAvailability.converted_end_time.date,
        selectedTimeZone
      );

      let meetingDate = selectedAvailability.start_time.split("T")[0];

      console.log("startTime: ", startTime);
      console.log("endTime: ", endTime);
      console.log("meetingDate: ", meetingDate);

      let dataJson = {
        scp_id: selectedUser,
        scp_name: selectedUserDetails.name,
        scp_phone: selectedUserDetails.phone,
        scp_timezone: selectedUserDetails.timezone,
        [userRole === "student" ? "student_id" : "patient_id"]: student.id,
        patient_name: student.name,
        patient_phone: student.whatsapp_phone_no,
        patient_timezone: selectedTimeZone,
        meeting_date: meetingDate,
        start_time: startTime,
        end_time: endTime,
      };

      try {
        const response = await axios.post(
          "https://backend.trayaschedule.hsciglobal.org/create-student-appointment",
          dataJson
        );

        if (response.status === 200) {
          console.log("Booking confirmed:", response.data);
        } else {
          setIsSubmitting(false);
          console.error("Booking failed with status:", response.status);
        }

        const table = userRole === "student" ? "students" : "scp";
        const { data, error } = await supabase
          .from(table)
          .update({
            timezone: selectedTimeZone,
          })
          .eq("id", student.id)
          .select();

        if (error) {
          console.error("Supabase update error:", error.message);
          throw error;
        }

        if (data && data.length > 0) {
          console.log("Update Successful!");
          setIsSubmitting(false);
          setIsSuccess(true);
          handleDialogClose();
        } else {
          setIsSubmitting(false);
          console.error("No records updated.");
        }
      } catch (error) {
        setIsSubmitting(false);
        console.error("An error occurred during booking or updating:", error);
      }
    } else {
      console.error("No slot selected, booking not attempted.");
    }
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "stretch" : "center",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        <Select
          value={timeRange}
          onChange={handleTimeRangeChange}
          displayEmpty
          sx={{ width: isMobile ? "100%" : "150px" }}
        >
          <MenuItem value="" disabled>
            Select Timeframe
          </MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="week">This Week</MenuItem>
          <MenuItem value="month">This Month</MenuItem>
        </Select>
        <TextField
          select
          label="User"
          value={selectedUserDetails?.name}
          onChange={handleUserChange}
          sx={{ width: isMobile ? "100%" : "150px" }}
        >
          <MenuItem value="" disabled>
            Select User
          </MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user}>
              {user.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {availabilities.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", overflowX: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Slot</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availabilities.map((availability) => (
                <TableRow key={availability.id}>
                  <TableCell>
                    {formatDate(availability.converted_start_time?.date)}
                  </TableCell>
                  <TableCell>
                    {/* {availability.converted_start_time?.time.slice(0, 5)} -{" "}
                    {availability.converted_end_time?.time.slice(0, 5)} */}
                    {format(
                      parse(
                        `${availability.converted_start_time.date} ${availability.converted_start_time.time}`,
                        "yyyy-MM-dd HH:mm:ss",
                        0
                      ),
                      "hh:mm aaaa"
                    )}{" "}
                    -{" "}
                    {format(
                      parse(
                        `${availability.converted_end_time.date} ${availability.converted_end_time.time}`,
                        "yyyy-MM-dd HH:mm:ss",
                        0
                      ),
                      "hh:mm aaaa"
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleBookClick(
                          availability,
                          `${availability.converted_start_time?.time.slice(
                            0,
                            5
                          )}-${availability.converted_end_time?.time.slice(
                            0,
                            5
                          )}`
                        )
                      }
                      sx={{ width: isMobile ? "100%" : "auto" }}
                    >
                      Book
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>Please select a user from the dropdown</Typography>
      )}
      {isLoading ? (
        <Typography
          style={{
            marginTop: "25px",
          }}
        >
          Loading...
        </Typography>
      ) : null}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Select a Time Slot</DialogTitle>
        <DialogContent
          sx={{
            textAlign: "center",
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="time-slot"
              name="time-slot-group"
              value={selectedSlot}
              onChange={handleSlotSelection}
            >
              {timeSlots.map((slot, index) => (
                <FormControlLabel
                  key={index}
                  value={slot}
                  control={<Radio />}
                  label={slot}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmBooking}
            variant="contained"
            disabled={!selectedSlot || isSubmitting}
          >
            Confirm
          </Button>
          <Button onClick={handleDialogClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchAllAvailability;
