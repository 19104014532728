import React, { useState } from "react";
import axios from "axios";
import DataTable from "../components/DataTable";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

const ScreeningBackend = () => {
  const [triggerAPI, setTriggerApi] = useState(false);
  const [finalTableData, setFinalTableData] = useState([]);
  const [jobDescription, setJobDescription] = useState("");
  const [resumeFile, setResumeFile] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  const navigate = useNavigate();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setResumeFile(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64Data = reader.result;
          setPdfData(base64Data);
        };
      }
    },
  });

  const fetchData = async () => {
    try {
      if (!resumeFile) return;

      const formData = new FormData();
      formData.append("resume", resumeFile);

      const uploadRes = await axios.post(
        "https://backend.trayaschedule.hsciglobal.org/upload-resume",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const resumePath = uploadRes.data.path;

      const layoutParserRes = await axios.post(
        "https://backend.trayaschedule.hsciglobal.org/parse",
        {
          resumePath,
        }
      );
      console.log("layoutParserRes: ", layoutParserRes);

      const concatenatedText = layoutParserRes.data.sentences
        .map((obj) => obj.text)
        .join("\n");
      const skillsRes = await axios.post(
        "https://backend.trayaschedule.hsciglobal.org/extract-skills",
        {
          concatenatedText,
        }
      );
      console.log("skillsRes: ", skillsRes);

      const finalJsonData = layoutParserRes.data.sentences.map((entry) => {
        const matchedSkills = skillsRes.data.data.reduce((acc, skillEntry) => {
          const skillName = skillEntry.skill.name;
          if (entry.text.includes(skillName)) {
            acc.push({
              name: skillEntry.skill.name,
              skill: skillEntry.skill.type.name,
            });
          }
          return acc;
        }, []);

        const claimId = Math.floor(Math.random() * 0xffff)
          .toString(16)
          .padStart(4, "0");

        return {
          claimId: claimId,
          text: entry.text,
          bounding_box: entry.bounding_box,
          skills: matchedSkills,
          page: entry.page,
        };
      });

      setFinalTableData(finalJsonData);

      const paramId = new Date().getTime();
      localStorage.setItem(
        "finalTableData-" + paramId,
        JSON.stringify({
          finalJsonData: finalJsonData,
          resumePath: pdfData,
          jobDescription: jobDescription,
          image_height: layoutParserRes.data.image_height,
          image_width: layoutParserRes.data.image_width,
        })
      );

      setTimeout(() => {
        navigate("/screening-copilot?id=" + paramId);
      }, 3000);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTriggerApi(true);
    fetchData();
  };

  return triggerAPI ? (
    <Box mt={4} textAlign="center">
      {finalTableData.length > 0 ? (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              "& .loading-dots": {
                display: "inline-block",
                width: "20px",
                "&::after": {
                  content: '"."',
                  animation: "loadingDots 1.5s infinite",
                },
                "@keyframes loadingDots": {
                  "0%": { content: '"."' },
                  "33%": { content: '".."' },
                  "66%": { content: '"..."' },
                },
              },
              fontSize: "18px",
            }}
          >
            Resume parsed! Redirecting...
          </Typography>
          {/* <DataTable data={finalTableData} /> */}
        </>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            "& .loading-dots": {
              display: "inline-block",
              width: "20px",
              "&::after": {
                content: '"."',
                animation: "loadingDots 1.5s infinite",
              },
              "@keyframes loadingDots": {
                "0%": { content: '"."' },
                "33%": { content: '".."' },
                "66%": { content: '"..."' },
              },
            },
            fontSize: "18px",
          }}
        >
          Preparing your experience. Please hang tight
          <span className="loading-dots"></span>
        </Typography>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#f4f6f8",
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "80%",
          padding: 4,
          bgcolor: "#ffffff",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={6}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  height: "92%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  bgcolor: isDragActive ? "#e0f7fa" : "#fafafa",
                  transition: "background-color 0.3s ease",
                  margin: "15px",
                }}
              >
                <input {...getInputProps()} />
                <Typography variant="body1" color="textSecondary">
                  {isDragActive
                    ? "Drop the resume file here ..."
                    : resumeFile && resumeFile.path
                    ? resumeFile.path
                    : "Drag 'n' drop a resume file here, or click to select file"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Job Description"
                multiline
                rows={12}
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                  height: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Box mt={4} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!resumeFile || !jobDescription}
            >
              Parse Resume
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default ScreeningBackend;
