import React from "react";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  Alert,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import moment from "moment-timezone";

// Utility function to format date and time to a specific timezone
const formatDateTime = (dateString, timeString, timezone) => {
  const [start_time] = timeString.split("-");
  const dateTime = `${dateString}T${start_time}:00Z`; // Combine date and time in UTC format
  const localDateTime = moment.tz(dateTime, timezone);

  const formattedDate = localDateTime.format("MMMM Do, YYYY");
  const formattedTime = localDateTime.format("h:mm A");

  return { formattedDate, formattedTime };
};

const MatchedPageDetails = ({ matchedDetails, scpDetails, timezone }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (
    Object.keys(matchedDetails).length === 0 ||
    Object.keys(scpDetails).length === 0 ||
    !timezone
  ) {
    return null;
  }

  const { meeting_date, meeting_time } = matchedDetails;
  const { scp_credentials, name } = scpDetails;

  const { formattedDate, formattedTime } = formatDateTime(
    meeting_date,
    meeting_time,
    timezone
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Alert Section */}
      <Alert severity="warning" sx={{ mb: 4 }}>
        You have arrived before the permitted time. Please wait until your
        scheduled meeting.
      </Alert>

      {/* Header Section */}
      <Box mb={4} textAlign={isMobile ? "center" : "left"}>
        <Typography variant="h4" component="div" gutterBottom>
          Since You're Early, Here's What You Need to Know
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We appreciate your punctuality. While you wait for your scheduled
          meeting, please review the details of your meeting partner and their
          profile below.
        </Typography>
      </Box>

      {/* Details Section */}
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Meeting Information
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <strong>Meeting Date:</strong> {formattedDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <strong>Meeting Time:</strong> {formattedTime} ({timezone})
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <strong>Meeting Partner:</strong> {name}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Profile Section */}
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h6" gutterBottom>
          Explore Their Profile!
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {scp_credentials ? (
          <iframe
            src={scp_credentials}
            width="100%"
            height="400px"
            style={{ border: "none" }}
            title="Meeting Partner Profile"
          />
        ) : (
          <Typography variant="body1" color="text.secondary">
            Profile information is not available at the moment.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default MatchedPageDetails;
