import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Divider,
  Snackbar,
  Alert,
  Tooltip,
  Grid,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAllAppointmentsForUser } from "../controllers/appointment";
import { supabase } from "../utils/supabase";
import moment from "moment-timezone";

const styles = {
  accordion: {
    "& .MuiAccordionSummary-root": {
      minHeight: 48, // Default minHeight
    },
    "& .MuiAccordionDetails-root": {
      py: 0, // Remove vertical padding from details
    },
  },
  meetingItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 1, // Reduced from py: 2 (16px -> 8px)
    px: 2, // Reduced from px: 3 (24px -> 16px)
    boxSizing: "border-box", // Ensure padding is included in height
  },
  meetingHeader: {
    display: "flex",
    alignItems: "center",
    mb: 0, // Removed bottom margin
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  participants: {
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: 400,
      p: 2,
    },
  },
  accordionDetails: {
    height: 300, // Fixed height for all accordion details
    overflow: "hidden", // Prevent overflow
  },
  scrollableList: {
    height: "100%",
    overflow: "auto",
    pt: 0,
    boxSizing: "border-box", // Ensure padding is included in height
  },
};

const Meetings = ({ user }) => {
  const [meetingsData, setMeetingsData] = useState({
    past: [],
    upcoming: [],
    cancelled: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Calculate the height for the upcoming meetings section
  const calculateUpcomingHeight = () => {
    const itemHeight = isMobile ? 130 : 75; // Approximate height of each meeting item (adjust as needed)
    const maxHeight = 300; // Maximum height
    const calculatedHeight = meetingsData.upcoming.length * itemHeight;

    // Return the smaller of the calculated height or the max height
    return Math.min(calculatedHeight, maxHeight);
  };

  useEffect(() => {
    getAllAppointmentsForUser(user.id).then((res) => {
      const now = moment.utc();
      const transformedData = res.reduce(
        (acc, meeting) => {
          const formattedMeeting = formatMeeting(meeting, user.timezone);

          if (meeting.status === "cancelled") {
            acc.cancelled.push(formattedMeeting);
          } else {
            const meetingDate = moment.utc(
              `${meeting.meeting_date}T${meeting.meeting_time.split("-")[0]}`
            );
            if (meetingDate.isBefore(now)) {
              acc.past.push(formattedMeeting);
            } else {
              acc.upcoming.push(formattedMeeting);
            }
          }
          return acc;
        },
        { past: [], upcoming: [], cancelled: [] }
      );
      setMeetingsData(transformedData);
    });
  }, [user.id, user.timezone]);

  const formatMeeting = (meeting, timezone) => {
    const meetingDateUTC = moment.utc(
      `${meeting.meeting_date}T${meeting.meeting_time.split("-")[0]}`
    );
    const meetingDateUserTZ = meetingDateUTC.clone().tz(timezone);
    const meetingLinkSubUrl =
      user.id === meeting.scp_id
        ? Object.keys(meeting.meeting_link_scp)[0] + "&role=s"
        : Object.keys(meeting.meeting_link_patient)[0] + "&role=p";

    return {
      id: meeting.id,
      date: meetingDateUserTZ.format("D MMMM YYYY"),
      time: meetingDateUserTZ.format("h:mm A"),
      participants: [
        meeting.patient?.name || meeting.student?.name || "",
        meeting.scp.name,
      ],
      meetingDateUTC,
      status: meeting.status,
      meetingLink:
        "https://trayaschedule.hsciglobal.org/meeting?" + meetingLinkSubUrl,
    };
  };

  const handleCancelMeeting = async () => {
    if (!selectedMeeting) return;
    setIsCancelling(true);
    try {
      const { error } = await supabase
        .from("appointments")
        .update({ status: "cancelled" })
        .eq("id", selectedMeeting.id);
      if (error) throw error;

      setMeetingsData((prev) => ({
        ...prev,
        upcoming: prev.upcoming.filter(
          (meeting) => meeting.id !== selectedMeeting.id
        ),
        cancelled: [
          ...prev.cancelled,
          { ...selectedMeeting, status: "cancelled" },
        ],
      }));
      setSnackbar({
        open: true,
        message: "Meeting cancelled successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error cancelling meeting:", error);
      setSnackbar({
        open: true,
        message: "Failed to cancel meeting",
        severity: "error",
      });
    } finally {
      setIsCancelling(false);
      setOpenDialog(false);
      setSelectedMeeting(null);
    }
  };

  const generateGoogleCalendarLink = (meeting) => {
    const start = meeting.meetingDateUTC.format("YYYYMMDDTHHmmss[Z]");
    const end = meeting.meetingDateUTC
      .clone()
      .add(1, "hour")
      .format("YYYYMMDDTHHmmss[Z]");
    const details = `Participants: ${meeting.participants.join(", ")}`;
    const text = "Meeting with " + meeting.participants.join(" and ");
    const location = meeting.meetingLink;

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}%2F${end}&details=${encodeURIComponent(
      details
    )}&text=${encodeURIComponent(text)}&location=${encodeURIComponent(
      location
    )}`;
  };

  const renderMeetingItem = (meeting, index, isUpcoming, arrayLength) => (
    <Box key={index}>
      <ListItem sx={styles.meetingItem}>
        <Box sx={styles.meetingInfo}>
          <Box sx={styles.meetingHeader}>
            <Typography
              variant="subtitle1"
              style={{
                cursor: isUpcoming ? "pointer" : "default",
                color: isUpcoming ? "blue" : "inherit",
              }}
              onClick={() => {
                if (isUpcoming) {
                  window.open(
                    meeting.meetingLink,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }
              }}
            >
              {`${meeting.date} at ${meeting.time}`}
            </Typography>
            {meeting.status === "cancelled" && (
              <Chip
                label="Cancelled"
                color="error"
                size="small"
                sx={{
                  ml: 1.5,
                  paddingTop: "18px",
                  "& .MuiChip-label": {
                    fontSize: "0.75rem",
                  },
                }}
              />
            )}
          </Box>
          <Typography sx={styles.participants}>
            {`Participants: ${meeting.participants.join(", ")}`}
          </Typography>
        </Box>
        {isUpcoming && meeting.status !== "cancelled" && (
          <Box sx={styles.actionButtons}>
            <Tooltip title="Add to Google Calendar" arrow>
              <IconButton
                color="primary"
                href={generateGoogleCalendarLink(meeting)}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "36px !important",
                  height: "36px !important",
                  padding: "8px !important",
                  backgroundColor: "#fff",
                  border: "1px solid #1976d2",
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.04)",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px !important",
                  },
                }}
              >
                <EventIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel Meeting" arrow>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setSelectedMeeting(meeting);
                  setOpenDialog(true);
                }}
                sx={{
                  width: "36px !important",
                  height: "36px !important",
                  minWidth: "36px !important",
                  padding: "8px !important",
                  backgroundColor: "#fff !important",
                  border: "1px solid #d32f2f !important",
                  color: "#d32f2f !important",
                  borderRadius: "4px",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "rgba(211, 47, 47, 0.04) !important",
                    boxShadow: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px !important",
                  },
                }}
              >
                <CancelIcon />
              </Button>
            </Tooltip>
          </Box>
        )}
      </ListItem>
      {index < arrayLength - 1 && <Divider />}
    </Box>
  );

  // return (
  //   <>
  //     <Grid container spacing={2} md={12}>
  //       <Grid item xs={12} md={12}>
  //         <Accordion
  //           defaultExpanded
  //           sx={{
  //             ...styles.accordion,
  //             "& .MuiAccordionSummary-root": {
  //               minHeight: meetingsData.upcoming.length === 1 ? 36 : 48, // Adjust minHeight based on the number of items
  //             },
  //           }}
  //         >
  //           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
  //             <Typography variant="h6">Upcoming Meetings</Typography>
  //           </AccordionSummary>
  //           <AccordionDetails sx={styles.accordionDetails}>
  //             <List sx={styles.scrollableList}>
  //               {meetingsData.upcoming.map((meeting, index) =>
  //                 renderMeetingItem(
  //                   meeting,
  //                   index,
  //                   true,
  //                   meetingsData.upcoming.length
  //                 )
  //               )}
  //               {meetingsData.upcoming.length === 0 && (
  //                 <Box
  //                   sx={{
  //                     height: "100%",
  //                     display: "flex",
  //                     alignItems: "center",
  //                     justifyContent: "center",
  //                   }}
  //                 >
  //                   <Typography variant="body2">
  //                     No upcoming meetings
  //                   </Typography>
  //                 </Box>
  //               )}
  //             </List>
  //           </AccordionDetails>
  //         </Accordion>
  //       </Grid>
  //     </Grid>

  return (
    <>
      <Grid container spacing={2} md={12}>
        <Grid item xs={12} md={12}>
          <Accordion
            defaultExpanded
            sx={{
              ...styles.accordion,
              "& .MuiAccordionSummary-root": {
                minHeight: meetingsData.upcoming.length === 1 ? 36 : 48, // Adjust minHeight based on the number of items
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Upcoming Meetings</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                height: calculateUpcomingHeight(), // Dynamic height
                overflow: "hidden", // Prevent overflow
              }}
            >
              <List sx={styles.scrollableList}>
                {meetingsData.upcoming.map((meeting, index) =>
                  renderMeetingItem(
                    meeting,
                    index,
                    true,
                    meetingsData.upcoming.length
                  )
                )}
                {meetingsData.upcoming.length === 0 && (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body2">
                      No upcoming meetings
                    </Typography>
                  </Box>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container spacing={2} md={12}>
        <Grid item xs={12} md={6}>
          <Accordion sx={styles.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Cancelled Meetings</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.accordionDetails}>
              <List sx={styles.scrollableList}>
                {meetingsData.cancelled.map((meeting, index) =>
                  renderMeetingItem(
                    meeting,
                    index,
                    false,
                    meetingsData.cancelled.length
                  )
                )}
                {meetingsData.cancelled.length === 0 && (
                  <Box sx={{ p: 2, textAlign: "center" }}>
                    No cancelled meetings
                  </Box>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Accordion sx={styles.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Past Meetings</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.accordionDetails}>
              <List sx={styles.scrollableList}>
                {meetingsData.past.map((meeting, index) =>
                  renderMeetingItem(
                    meeting,
                    index,
                    false,
                    meetingsData.past.length
                  )
                )}
                {meetingsData.past.length === 0 && (
                  <Box sx={{ p: 2, textAlign: "center" }}>No past meetings</Box>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={styles.dialog}
      >
        <DialogTitle>Cancel Meeting</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this meeting? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>No, Keep Meeting</Button>
          <Button
            onClick={handleCancelMeeting}
            color="error"
            variant="contained"
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Yes, Cancel Meeting"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        sx={{
          "& .MuiSnackbarContent-root": {
            display: "flex",
            alignItems: "center",
            gap: 1,
            minHeight: "48px",
          },
        }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          sx={{
            width: "100%",
            alignItems: "center",
            "& .MuiAlert-action": {
              padding: "0 8px",
              marginRight: 0,
              alignSelf: "center",
            },
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Meetings;
