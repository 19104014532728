import { supabase } from "../utils/supabase";

export const getAdminByPhone = async (phone) => {
  try {
    const { data, error } = await supabase
      .from("admins")
      .select("*")
      .eq("whatsapp_phone_no", phone)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching Admin by Phone:", error.message);
    return null;
  }
};
