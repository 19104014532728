import React from "react";

const DataTable = ({ data }) => {
  return (
    <table
      border="1"
      cellPadding="10"
      cellSpacing="0"
      style={{ width: "100%", textAlign: "left" }}
    >
      <thead>
        <tr>
          <th>Claim ID</th>
          <th>Text</th>
          <th>Skills</th>
          <th>Question</th>
          <th>Answers</th>
          <th>Follow-Up Questions</th>
          <th>Follow-Up Answers</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.claimId}>
            <td>{item.claimId}</td>
            <td>{item.text}</td>
            <td>
              {item.skills && item.skills.length > 0
                ? item.skills.map((skill, index) => (
                    <div key={index}>{skill.name}</div>
                  ))
                : "N/A"}
            </td>
            <td>{item.question || "N/A"}</td>
            <td>
              {item.answers && item.answers.length > 0
                ? item.answers.map((answer, index) => (
                    <div key={index}>- {answer.text}</div>
                  ))
                : "N/A"}
            </td>
            <td>{item.followUpQuestions || "N/A"}</td>
            <td>
              {item.followUpAnswers && item.followUpAnswers.length > 0
                ? item.followUpAnswers.map((answer, index) => (
                    <div key={index}>- {answer.text}</div>
                  ))
                : "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
