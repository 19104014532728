export const countries = [
  { name: "United States", code: "US", dial_code: "+1" },
  { name: "United Kingdom", code: "GB", dial_code: "+44" },
  { name: "Australia", code: "AU", dial_code: "+61" },
  { name: "India", code: "IN", dial_code: "+91" },
  { name: "Germany", code: "DE", dial_code: "+49" },
  { name: "France", code: "FR", dial_code: "+33" },
  { name: "Japan", code: "JP", dial_code: "+81" },
  { name: "China", code: "CN", dial_code: "+86" },
  { name: "Brazil", code: "BR", dial_code: "+55" },
  { name: "South Africa", code: "ZA", dial_code: "+27" },
  { name: "Mexico", code: "MX", dial_code: "+52" },
  { name: "Russia", code: "RU", dial_code: "+7" },
  { name: "Italy", code: "IT", dial_code: "+39" },
  { name: "Spain", code: "ES", dial_code: "+34" },
  { name: "Argentina", code: "AR", dial_code: "+54" },
  { name: "Netherlands", code: "NL", dial_code: "+31" },
  { name: "Turkey", code: "TR", dial_code: "+90" },
  { name: "Switzerland", code: "CH", dial_code: "+41" },
  { name: "South Korea", code: "KR", dial_code: "+82" },
  { name: "Sweden", code: "SE", dial_code: "+46" },
  { name: "Belgium", code: "BE", dial_code: "+32" },
  { name: "Norway", code: "NO", dial_code: "+47" },
  { name: "Austria", code: "AT", dial_code: "+43" },
  { name: "Denmark", code: "DK", dial_code: "+45" },
  { name: "New Zealand", code: "NZ", dial_code: "+64" },
  { name: "Ireland", code: "IE", dial_code: "+353" },
  { name: "Singapore", code: "SG", dial_code: "+65" },
  { name: "Hong Kong", code: "HK", dial_code: "+852" },
  { name: "Malaysia", code: "MY", dial_code: "+60" },
  { name: "Thailand", code: "TH", dial_code: "+66" },
  { name: "Philippines", code: "PH", dial_code: "+63" },
  { name: "Indonesia", code: "ID", dial_code: "+62" },
  { name: "Saudi Arabia", code: "SA", dial_code: "+966" },
  { name: "United Arab Emirates", code: "AE", dial_code: "+971" },
  { name: "Israel", code: "IL", dial_code: "+972" },
  { name: "Egypt", code: "EG", dial_code: "+20" },
  { name: "Nigeria", code: "NG", dial_code: "+234" },
  { name: "Kenya", code: "KE", dial_code: "+254" },
  { name: "Ghana", code: "GH", dial_code: "+233" },
  { name: "Pakistan", code: "PK", dial_code: "+92" },
  { name: "Bangladesh", code: "BD", dial_code: "+880" },
  { name: "Sri Lanka", code: "LK", dial_code: "+94" },
  { name: "Nepal", code: "NP", dial_code: "+977" },
  { name: "Vietnam", code: "VN", dial_code: "+84" },
  { name: "South Africa", code: "ZA", dial_code: "+27" },
  { name: "Colombia", code: "CO", dial_code: "+57" },
  { name: "Venezuela", code: "VE", dial_code: "+58" },
  { name: "Peru", code: "PE", dial_code: "+51" },
  { name: "Chile", code: "CL", dial_code: "+56" },
  { name: "Portugal", code: "PT", dial_code: "+351" },
  { name: "Greece", code: "GR", dial_code: "+30" },
  { name: "Poland", code: "PL", dial_code: "+48" },
  { name: "Romania", code: "RO", dial_code: "+40" },
  { name: "Czech Republic", code: "CZ", dial_code: "+420" },
  { name: "Hungary", code: "HU", dial_code: "+36" },
  { name: "Ukraine", code: "UA", dial_code: "+380" },
  { name: "Belarus", code: "BY", dial_code: "+375" },
  { name: "Slovakia", code: "SK", dial_code: "+421" },
  { name: "Bulgaria", code: "BG", dial_code: "+359" },
  { name: "Croatia", code: "HR", dial_code: "+385" },
  { name: "Slovenia", code: "SI", dial_code: "+386" },
  { name: "Lithuania", code: "LT", dial_code: "+370" },
  { name: "Latvia", code: "LV", dial_code: "+371" },
  { name: "Estonia", code: "EE", dial_code: "+372" },
  { name: "Finland", code: "FI", dial_code: "+358" },
  { name: "Iceland", code: "IS", dial_code: "+354" },
];
