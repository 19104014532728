import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LanguagePreferences = ({ preferences, handleCheckboxChange }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" sx={{ fontFamily: "Arial, sans-serif" }}>
          Language Preferences
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
          height: "250px", // Fixed height for both sections
          overflowY: "auto", // Enable scrolling if content overflows
        }}
      >
        {[
          "english",
          "hindi",
          "marathi",
          "tamil",
          "telugu",
          "kannada",
          "bengali",
        ].map((lang) => (
          <label key={lang} className="language-label">
            <input
              type="checkbox"
              className="language-checkbox"
              checked={preferences[lang]}
              onChange={() => handleCheckboxChange(lang)}
            />
            {lang.charAt(0).toUpperCase() + lang.slice(1)}
          </label>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default LanguagePreferences;
