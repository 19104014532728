import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  VideoCall as VideoCallIcon,
  Event as EventIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryContainer,
} from "victory";
import {
  getTotalMeetingsToday,
  getTotalMeetingsThisWeek,
  getTotalMeetingsThisMonth,
  getWellScheduledMeetingsToday,
  getWellScheduledMeetingsThisWeek,
  getWellScheduledMeetingsThisMonth,
  getLeastScheduledMeetingsToday,
  getLeastScheduledMeetingsThisWeek,
  getLeastScheduledMeetingsThisMonth,
} from "../controllers/analytics";
import { getAllSCPs } from "../controllers/scp";
import { getAdminByPhone } from "../controllers/admins";
import { useLocation } from "react-router-dom";
import { supabase } from "../utils/supabase";
import queryString from "query-string";
import SearchSCPAvailability from "../components/analytics/SearchSCPAvailability";

const drawerWidth = 240;

function SchedulerPage() {
  const [data, setData] = useState([]);
  const [wellScheduledData, setWellScheduledData] = useState([]);
  const [leasePerformingData, setLeastPerformingData] = useState([]);
  const [timeRange, setTimeRange] = useState("week");
  const [selectedUser, setSelectedUser] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [expandedMetric, setExpandedMetric] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState("liveAnalytics");
  const [users, setUsers] = useState([]);
  const [topPerformer, setTopPerformer] = useState("");
  const [verified, setVerified] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const verifyUser = async () => {
    const params = queryString.parse(location.search);

    if (params.id) {
      const columnName = "sdo_link";
      const { data, error } = await supabase
        .from("admins")
        .select(`id, ${columnName}`);

      if (error) {
        console.error("Error fetching data from Supabase:", error);
      } else if (data && data.length > 0) {
        // Find the row where the JSON column contains the key
        const row = data.find((row) => row[columnName][params.id]);

        if (row) {
          getAdminByPhone(row[columnName][params.id]).then((res) => {
            if (res) {
              setVerified(true);
            }
          });
        } else {
          console.error("Key not found in any row's JSON object");
        }
      }
    }
  };

  useEffect(() => {
    verifyUser();
  }, [location, setVerified]);

  useEffect(() => {
    let top = "";
    if (wellScheduledData.length > 0) {
      let row = wellScheduledData[0];
      top = getNameById(row.user_id);
    }
    setTopPerformer(top);
  }, [wellScheduledData]);

  useEffect(() => {
    getAllSCPs().then((res) => {
      let arr = res.map((item) => ({ id: item.id, name: item.name }));
      setUsers(arr);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let fetchedData = [];
      let fetchedWellScheduledData = [];
      let fetchLeastPerformingUsers = [];
      try {
        if (timeRange === "today") {
          fetchedData = await getTotalMeetingsToday();
          fetchedWellScheduledData = await getWellScheduledMeetingsToday();
          fetchLeastPerformingUsers = await getLeastScheduledMeetingsToday();
        } else if (timeRange === "week") {
          fetchedData = await getTotalMeetingsThisWeek();
          fetchedWellScheduledData = await getWellScheduledMeetingsThisWeek();
          fetchLeastPerformingUsers = await getLeastScheduledMeetingsThisWeek();
        } else if (timeRange === "month") {
          fetchedData = await getTotalMeetingsThisMonth();
          fetchedWellScheduledData = await getWellScheduledMeetingsThisMonth();
          fetchLeastPerformingUsers =
            await getLeastScheduledMeetingsThisMonth();
        }
        setData(fetchedData);
        setWellScheduledData(fetchedWellScheduledData);
        setLeastPerformingData(fetchLeastPerformingUsers);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [timeRange]);

  useEffect(() => {
    const filterData = (data, user) => {
      return user === "all"
        ? data
        : data.filter(
            (item) => item.scp_id === user || item.patient_id === user
          );
    };

    const filteredData = filterData(data, selectedUser);

    setFilteredData(filteredData);

    const mergeData = (meetings) => {
      const dates = Array.from(
        new Set(meetings.map((item) => item.meeting_date))
      );

      const merged = dates.map((date) => {
        const meeting = meetings.filter((item) => item.meeting_date === date);
        return {
          date,
          meetings: meeting.length,
        };
      });
      // Sort the merged data by date in ascending order
      merged.sort((a, b) => new Date(a.date) - new Date(b.date));

      setMergedData(merged);
    };

    mergeData(filteredData);
  }, [data, selectedUser]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleExpandClick = (metric) => {
    setExpandedMetric(expandedMetric === metric ? null : metric);
  };

  const DrawerContent = ({
    selectedMenuItem,
    setSelectedMenuItem,
    handleDrawerToggle = () => {},
  }) => (
    <List>
      <ListItem
        button
        selected={selectedMenuItem === "liveAnalytics"}
        onClick={() => {
          setSelectedMenuItem("liveAnalytics");
          handleDrawerToggle();
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Live Analytics" />
      </ListItem>
      <ListItem
        button
        selected={selectedMenuItem === "scpSchedule"}
        onClick={() => {
          setSelectedMenuItem("scpSchedule");
          handleDrawerToggle();
        }}
      >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary="Search SCP Availability" />
      </ListItem>
      <ListItem
        button
        selected={selectedMenuItem === "createJitsi"}
        onClick={() => {
          setSelectedMenuItem("createJitsi");
          handleDrawerToggle();
        }}
      >
        <ListItemIcon>
          <VideoCallIcon />
        </ListItemIcon>
        <ListItemText primary="Create Jitsi Meeting" />
      </ListItem>
      <ListItem
        button
        selected={selectedMenuItem === "schedulePatients"}
        onClick={() => {
          setSelectedMenuItem("schedulePatients");
          handleDrawerToggle();
        }}
      >
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Schedule Patients" />
      </ListItem>
      <ListItem
        button
        selected={selectedMenuItem === "personalSettings"}
        onClick={() => {
          setSelectedMenuItem("personalSettings");
          handleDrawerToggle();
        }}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Personal Settings" />
      </ListItem>
      <ListItem
        button
        selected={selectedMenuItem === "reports"}
        onClick={() => {
          setSelectedMenuItem("reports");
          handleDrawerToggle();
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </List>
  );

  // Helper functions to calculate KPIs
  const totalMeetings = filteredData.length;
  const totalUnderPerformers = leasePerformingData.length;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  function getNameById(id) {
    const user = users.find((user) => user.id === id);
    return user ? user.name : null;
  }

  const TotalMeetingsChart = () => {
    return (
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={20}
        height={300}
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              touchAction: "auto",
            }}
          />
        }
      >
        <VictoryAxis
          style={{
            tickLabels: {
              fontSize: 10,
              angle: timeRange === "month" ? -45 : 0,
              textAnchor: timeRange === "month" ? "end" : "middle",
            },
          }}
          tickFormat={(tick) => {
            const formattedDate = formatDate(tick);
            return formattedDate.includes(",")
              ? formattedDate.split(",")[0]
              : formattedDate;
          }}
        />
        <VictoryAxis dependentAxis />
        <VictoryBar
          data={mergedData}
          x="date"
          y="meetings"
          style={{ data: { fill: "#1E90FF" } }}
        />
      </VictoryChart>
    );
  };

  const UnderperformersChart = () => {
    // Assuming leasePerformingData is defined and available
    const maxCount = Math.max(
      ...leasePerformingData.map((meeting) => meeting.count)
    );

    return (
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={20}
        height={350}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          style={{
            tickLabels: { fontSize: 11, angle: -45, padding: 10 },
            axisLabel: { padding: 30 },
          }}
          tickValues={leasePerformingData.map((meeting) => meeting.user_id)}
          tickFormat={(tick) => {
            const name = getNameById(tick);
            return name ? name.split(" ")[0] + " ji" : "Unknown"; // Default to "Unknown" if null
          }}
        />
        <VictoryAxis
          dependentAxis
          tickValues={Array.from({ length: maxCount + 1 }, (_, i) => i)}
          tickFormat={(t) => t.toString()}
          label="Count"
          style={{ axisLabel: { padding: 40 } }}
        />
        <VictoryBar
          data={leasePerformingData.map((meeting) => ({
            user_id: meeting.user_id,
            count: meeting.count,
          }))}
          x="user_id"
          y="count"
          style={{ data: { fill: "#f44336" } }}
          barWidth={20}
        />
      </VictoryChart>
    );
  };

  const TopPerformersChart = () => {
    return (
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={20}
        height={300}
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              touchAction: "auto",
            }}
          />
        }
      >
        <VictoryAxis
          style={{
            tickLabels: {
              fontSize: 10,
              textAnchor: "middle",
            },
          }}
          tickValues={wellScheduledData.map((meeting) => meeting.user_id)}
          tickFormat={(tick) => {
            const name = getNameById(tick);
            if (name) {
              const firstName = name.split(" ")[0];
              return firstName ? firstName + " ji" : name + " ji";
            }
            return "";
          }}
        />
        <VictoryAxis dependentAxis />
        <VictoryBar
          data={wellScheduledData.map((meeting) => ({
            user_id: meeting.user_id,
            count: meeting.count,
          }))}
          x="user_id"
          y="count"
          style={{ data: { fill: "#4caf50" } }}
        />
      </VictoryChart>
    );
  };

  return verified ? (
    <div style={{ display: "flex" }}>
      <nav style={{ width: isMobile ? 0 : drawerWidth, flexShrink: 0 }}>
        {isMobile ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Drawer
            variant="permanent"
            open
            sx={{
              width: drawerWidth,
              "& .MuiDrawer-paper": { width: drawerWidth },
            }}
          >
            <DrawerContent
              selectedMenuItem={selectedMenuItem}
              setSelectedMenuItem={setSelectedMenuItem}
            />
          </Drawer>
        )}
      </nav>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <DrawerContent
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Drawer>
      <main style={{ flexGrow: 1, padding: "20px" }}>
        {selectedMenuItem === "liveAnalytics" && (
          <Grid container spacing={3}>
            {/* Header and Filters */}
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "15px",
              }}
            >
              <Typography
                variant="h4"
                style={{
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                SDO Performance Dashboard
              </Typography>

              <Select
                sx={{ width: "130px" }}
                value={timeRange}
                onChange={handleTimeRangeChange}
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="week">This Week</MenuItem>
                <MenuItem value="month">This Month</MenuItem>
              </Select>
              <TextField
                label="User"
                value={selectedUser}
                onChange={handleUserChange}
                select
                sx={{ width: "130px", marginLeft: "20px" }}
              >
                <MenuItem value="all">All</MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* KPI Cards */}
            <Grid container spacing={3} style={{ paddingLeft: "20px" }}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Total Meetings</Typography>
                    <Typography variant="h4">{totalMeetings}</Typography>
                    <IconButton
                      onClick={() => handleExpandClick("totalMeetings")}
                    >
                      {expandedMetric === "totalMeetings" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                    <Collapse
                      in={expandedMetric === "totalMeetings"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          padding: "8px",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "4px",
                          marginTop: "8px",
                          maxWidth: "100%",
                          overflow: "auto",
                        }}
                      >
                        {filteredData.map((meeting, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                padding: "4px 0",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "8px",
                                }}
                              >
                                {idx + 1}.
                              </span>
                              <span
                                style={{
                                  marginRight: "8px",
                                  color: "#555",
                                }}
                              >
                                {formatDate(meeting.meeting_date)}:
                              </span>
                              <span>
                                {getNameById(meeting.scp_id)} ji and{" "}
                                {getNameById(meeting.patient_id)} ji
                              </span>
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Collapse>
                  </CardContent>
                </Card>
              </Grid>
              {isMobile ? (
                <Grid item xs={12}>
                  <TotalMeetingsChart />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Underperformers</Typography>
                    <Typography variant="h4">{totalUnderPerformers}</Typography>
                    <IconButton
                      onClick={() => handleExpandClick("totalUnderPerformers")}
                    >
                      {expandedMetric === "totalUnderPerformers" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                    <Collapse
                      in={expandedMetric === "totalUnderPerformers"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          padding: "8px",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "4px",
                          marginTop: "8px",
                          maxWidth: "100%",
                          overflow: "auto",
                        }}
                      >
                        {leasePerformingData.map((meeting, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                padding: "4px 0",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "8px",
                                }}
                              >
                                {idx + 1}.
                              </span>
                              <span
                                style={{
                                  marginRight: "8px",
                                  color: "#555",
                                }}
                              >
                                {getNameById(meeting.user_id)} ji:
                              </span>
                              <span>
                                {meeting.count}{" "}
                                {meeting.count === 1 ? "meeting" : "meetings"}
                              </span>
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Collapse>
                  </CardContent>
                </Card>
              </Grid>
              {isMobile ? (
                <Grid item xs={12}>
                  <UnderperformersChart />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Top Performer</Typography>
                    <Typography variant="h4">
                      {topPerformer !== "" ? topPerformer + " ji" : "-"}
                    </Typography>
                    <IconButton
                      onClick={() => handleExpandClick("wellScheduledMeetings")}
                    >
                      {expandedMetric === "wellScheduledMeetings" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                    <Collapse
                      in={expandedMetric === "wellScheduledMeetings"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          padding: "8px",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "4px",
                          marginTop: "8px",
                          maxWidth: "100%",
                          overflow: "auto",
                        }}
                      >
                        {wellScheduledData.map((meeting, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                padding: "4px 0",
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "8px",
                                }}
                              >
                                {idx + 1}.
                              </span>
                              <span
                                style={{
                                  marginRight: "8px",
                                  color: "#555",
                                }}
                              >
                                {getNameById(meeting.user_id)} ji:
                              </span>
                              <span>
                                {meeting.count}{" "}
                                {meeting.count === 1 ? "meeting" : "meetings"}
                              </span>
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Collapse>
                  </CardContent>
                </Card>
              </Grid>
              {isMobile ? (
                <Grid item xs={12}>
                  <TopPerformersChart />
                </Grid>
              ) : null}
            </Grid>
            {!isMobile ? (
              <>
                {/* TotalMeetingsChart Chart */}
                <Grid item xs={12} md={4}>
                  <TotalMeetingsChart />
                </Grid>
                {/* Underperformers Chart */}
                <Grid item xs={12} md={4}>
                  <UnderperformersChart />
                </Grid>
                {/* Well Scheduled Meetings Chart */}
                <Grid item xs={12} md={4}>
                  <TopPerformersChart />
                </Grid>
              </>
            ) : null}

            {/* Individual Schedule View */}
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Meetings</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mergedData.map((row) => (
                      <TableRow key={row.date}>
                        <TableCell component="th" scope="row">
                          {formatDate(row.date)}
                        </TableCell>
                        <TableCell>{row.meetings}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        {selectedMenuItem === "scpSchedule" && <SearchSCPAvailability />}
        {selectedMenuItem === "createJitsi" && (
          <Typography variant="h5">
            Create Personal Jitsi Meeting Link - Coming Soon!
          </Typography>
        )}
        {selectedMenuItem === "schedulePatients" && (
          <Typography variant="h5">Schedule Patients - Coming Soon!</Typography>
        )}
        {selectedMenuItem === "personalSettings" && (
          <Typography variant="h5">Personal Settings - Coming Soon!</Typography>
        )}
        {selectedMenuItem === "reports" && (
          <Typography variant="h5">Reports - Coming Soon!</Typography>
        )}
      </main>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f4f4f9",
        color: "#333",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <span style={{ fontSize: "24px", textAlign: "center" }}>Loading...</span>
    </div>
  );
}

export default SchedulerPage;
