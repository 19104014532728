import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../utils/supabase";
import { getStudentByPhone } from "../controllers/students";
import queryString from "query-string";
import { Box, Typography, CircularProgress } from "@mui/material";
import { getSCPByPhone, getSCPById } from "../controllers/scp";
import BookPersonalAvailability from "../components/BookPersonalAvailability";

const PersonalScheduler = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [scpDetails, setScpDetails] = useState(null);

  const location = useLocation();

  const verifyUser = async () => {
    const params = queryString.parse(location.search);

    if (params.id && params.role) {
      const columnName = "preferences_link";
      const roleType = params.role === "student" ? "students" : "scp";
      setUserRole(params.role);

      const { data, error } = await supabase
        .from(roleType)
        .select(`id, name, ${columnName}`);

      if (error) {
        console.error("Error fetching data from Supabase:", error);
      } else if (data && data.length > 0) {
        // Find the row where the JSON column contains the key
        const row = data.find((row) => row[columnName][params.id]);

        if (row) {
          if (roleType === "students") {
            getStudentByPhone(row[columnName][params.id]).then((res) => {
              if (res) {
                setUser(res);
              }
            });
          } else {
            getSCPByPhone(row[columnName][params.id]).then((res) => {
              if (res) {
                setUser(res);
              }
            });
          }
        } else {
          console.error("Key not found in any row's JSON object");
        }
      }
    }

    if (params.redirectUrl) {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=19252305898&text=Personal%20Scheduler%20for%20ID%20" +
        params.redirectUrl +
        "&type=phone_number&app_absent=0";
    }

    if (params.scp_id) {
      getSCPById(params.scp_id).then((res) => {
        if (res) {
          setScpDetails(res);
        }
      });
    }
  };

  useEffect(() => {
    verifyUser();
  }, [location, setUser]);

  return user ? (
    isSuccess ? (
      <Typography
        variant="h6"
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "green",
          textAlign: "center",
          marginTop: "25px",
        }}
      >
        Your appointment has been successfully booked. We will contact you on
        Whatsapp shortly. Click{" "}
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setIsSuccess(false);
          }}
        >
          here
        </span>{" "}
        to return to the main page!
      </Typography>
    ) : (
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "#f0f4f8",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          maxWidth: "600px",
          margin: "auto",
          marginTop: "20px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Welcome, {user.name} ji
        </Typography>
        <BookPersonalAvailability
          student={user}
          userRole={userRole}
          setIsSuccess={setIsSuccess}
          scpDetails={scpDetails}
        />
      </Box>
    )
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f4f8",
      }}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ marginLeft: "16px" }}>
        Loading...
      </Typography>
    </Box>
  );
};

export default PersonalScheduler;
