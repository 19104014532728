import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { supabase } from "../utils/supabase";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { getSCPById, getStudentById } from "../controllers/scp";
import MatchedPageDetails from "../components/MatchedPageDetails";
import Draggable from "react-draggable";
import {
  Button,
  Typography,
  IconButton,
  Tooltip,
  Drawer,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import HSCI_LOGO from "../assets/hsci_logo_transparent.png";

const JitsiMeetComponent = () => {
  const apiRef = useRef();
  const [token, setToken] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [userName, setUserName] = useState("");
  const [appointmentId, setAppointmentId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [matchedDetails, setMatchedDetails] = useState([]);
  const [flag, setFlag] = useState(false);
  const [scpDetails, setScpDetails] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [showHelperText, setShowHelperText] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showTextOverlay, setShowTextOverlay] = useState(false);
  const [scpContext, setScpContext] = useState(null);
  const [patientContext, setPatientContext] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [position, setPosition] = useState({
    xPercent: 50,
    yPercent: 50,
  });
  const [minimizedPosition, setMinimizedPosition] = useState({
    xPercent: 95,
    yPercent: 50,
  });

  const location = useLocation();

  const handleStop = (e, data) => {
    const xPercent = (data.x / window.innerWidth) * 100;
    const yPercent = (data.y / window.innerHeight) * 100;
    setPosition({ xPercent, yPercent });
  };

  const handleMinimizedDrag = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    const yPercent = (data.y / window.innerHeight) * 100;
    setMinimizedPosition({ xPercent: 95, yPercent });
  };

  const styles = {
    overlay: {
      width: "350px",
      backgroundColor: "rgba(255, 255, 255, 0.95)",
      border: "none",
      borderRadius: "12px",
      boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
      position: "absolute",
      cursor: "move",
      zIndex: 1001,
    },
    cardHeader: {
      backgroundColor: userRole === "p" ? "#007bff" : "#035202",
      color: "white",
      padding: "16px",
    },
    cardContent: {
      padding: "16px",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    minimizedWidget: {
      width: "60px",
      height: "60px",
      backgroundColor: "#007bff",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      cursor: "pointer",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      zIndex: 1002,
    },
    helperText: {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    helperButton: {
      marginTop: "20px",
    },
  };

  const handleCloseHelper = () => {
    setShowHelperText(false);
    setShowTextOverlay(true);

    // Set showHelperText to false after 5 seconds
    setTimeout(() => {
      setShowTextOverlay(false);
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const handleMinimize = () => {
    setMinimized(true);
    setShowTextOverlay(false);
  };

  const handleExpand = () => {
    setMinimized(false);
    setShowTextOverlay(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const key = "room=" + params.get("room");
      const role = params.get("role");
      setUserRole(role);

      if (key && role) {
        let columnName, userId, personId;
        if (role === "p") {
          columnName = "meeting_link_patient";
          userId = "scp_id";
          personId = "patient_id";
        } else if (role === "s") {
          columnName = "meeting_link_scp";
          userId = "patient_id";
          personId = "scp_id";
        } else if (role === "st") {
          columnName = "meeting_link_patient";
          userId = "scp_id";
          personId = "student_id";
        } else {
          console.error("Invalid role specified in the URL");
          return;
        }

        const { data, error } = await supabase
          .from("appointments")
          .select(
            `id, ${columnName}, ${userId}, ${personId}, status, student_id, meeting_date, meeting_time, context_content`
          );

        if (error) {
          console.error("Error fetching data from Supabase:", error);
        } else if (data && data.length > 0) {
          const row = data.find((row) => row[columnName][key]);
          setAppointmentId(row.id);
          setMatchedDetails(row);

          let keys = Object.keys(row.context_content);
          setScpContext(keys[0]);
          setPatientContext(row.context_content[keys[0]]);

          const scpDetails = row.student_id
            ? role === "st"
              ? await getSCPById(row[userId])
              : await getStudentById(row["student_id"])
            : await getSCPById(row[userId]);

          setScpDetails(scpDetails);

          const personDetails =
            personId === "student_id"
              ? await getStudentById(row[personId])
              : await getSCPById(row[personId]);

          setTimezone(personDetails.timezone);

          if (row) {
            const meetingLink = row[columnName][key];
            const linkParams = new URLSearchParams(meetingLink);
            const jwtToken = linkParams.get("jwt");
            const room = linkParams.get("room");

            if (jwtToken) {
              setToken(jwtToken);
              const decoded = jwtDecode(jwtToken);
              if (decoded?.context?.user?.name) {
                setUserName(decoded.context.user.name);
              }
            }
            if (room) {
              setRoomName(room);
            }
          } else {
            console.error("Key not found in any row's JSON object");
          }
        }
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const checkMeetingTime = () => {
      if (
        !matchedDetails ||
        !matchedDetails.meeting_date ||
        !matchedDetails.meeting_time
      ) {
        return;
      }

      const now = new Date();
      const currentUTCDateTime = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      );

      const meetingDateTime = Date.UTC(
        parseInt(matchedDetails.meeting_date.substring(0, 4)),
        parseInt(matchedDetails.meeting_date.substring(5, 7)) - 1,
        parseInt(matchedDetails.meeting_date.substring(8, 10)),
        parseInt(matchedDetails.meeting_time.substring(0, 2)),
        parseInt(matchedDetails.meeting_time.substring(3, 5))
      );

      const timeDifference = meetingDateTime - currentUTCDateTime;

      if (matchedDetails.status !== "cancelled") {
        if (timeDifference <= 15 * 60 * 1000) {
          setFlag(false);
        } else {
          setFlag(true);
        }
      } else {
        setFlag(true);
      }
    };

    const intervalId = setInterval(checkMeetingTime, 1000);

    checkMeetingTime();

    return () => clearInterval(intervalId);
  }, [matchedDetails]);

  const handleApiReady = (apiObj) => {
    apiRef.current = apiObj;
    apiRef.current.on("readyToClose", handleReadyToClose);
    apiRef.current.on("videoConferenceJoined", () => {
      setTimeout(() => {
        apiRef.current.executeCommand("toggleTileView");
        setShowHelperText(true);
        setShowLogo(true);
      }, 1000);
    });
  };

  const handleReadyToClose = () => {
    console.log("Participant has left the meeting.");
    window.location.href =
      "/meeting-feedback?appt=" + appointmentId + "&role=" + userRole;
  };

  const Spinner = () => {
    return (
      <div style={spinnerStyle}>
        <div style={spinnerInnerStyle}></div>
      </div>
    );
  };

  const spinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const spinnerInnerStyle = {
    width: "40px",
    height: "40px",
    border: "5px solid rgba(0, 0, 0, 0.1)",
    borderTop: "5px solid #3498db",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      {flag ? (
        <MatchedPageDetails
          scpDetails={scpDetails}
          matchedDetails={matchedDetails}
          timezone={timezone}
          role={userRole}
        />
      ) : token && roomName ? (
        <>
          <JitsiMeeting
            domain="meet.hsciglobal.org"
            roomName={roomName}
            jwt={token}
            configOverwrite={{
              defaultLanguage: "en",
              userInfo: {
                displayName: userName,
              },
            }}
            interfaceConfigOverwrite={{
              filmStripOnly: false,
              SHOW_JITSI_WATERMARK: false,
              SHOW_BRAND_WATERMARK: false,
              SHOW_WATERMARK_FOR_GUESTS: false,
            }}
            onApiReady={handleApiReady}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.position = "fixed";
              iframeRef.style.top = "0";
              iframeRef.style.left = "0";
              iframeRef.style.width = "100%";
              iframeRef.style.height = "100%";
              iframeRef.style.border = "none";
            }}
            loadingComponent={Spinner}
          />
          {showLogo && (
            <img
              src={HSCI_LOGO}
              style={{
                position: "fixed",
                top: "5px",
                left: "5px",
                zIndex: 1000,
                maxWidth: "200px",
                height: "200px",
              }}
              alt="HSCI Logo"
            />
          )}
        </>
      ) : (
        <Spinner />
      )}

      {showHelperText && (
        <div style={styles.helperText}>
          <Typography variant="h6" align="center">
            The context will now appear on your screen. Drag/Minimize as per
            your convenience.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseHelper}
            style={styles.helperButton}
          >
            Dismiss
          </Button>
        </div>
      )}

      {!minimized && showTextOverlay && (
        <Draggable
          defaultPosition={{
            x: (window.innerWidth * position.xPercent) / 100,
            y: (window.innerHeight * position.yPercent) / 100,
          }}
          onStop={handleStop}
        >
          <Card style={styles.overlay}>
            <CardHeader
              avatar={<InfoIcon sx={{ color: "white" }} />} // White info icon
              action={
                <IconButton onClick={handleMinimize} sx={{ color: "white" }}>
                  <MinimizeIcon />
                </IconButton>
              }
              // title={userRole === "p" ? scpContext : "Context Information"}
              title="Information"
              sx={styles.cardHeader} // Apply header style
            />
            <CardContent sx={styles.cardContent}>
              <Typography variant="body2">
                {/* {userRole === "p" ? patientContext : scpContext} */}
                Please click on the 'leave meeting' button to exit.
              </Typography>
            </CardContent>
          </Card>
        </Draggable>
      )}

      {minimized && ( // Conditional rendering for drawer
        <Draggable
          axis="y"
          defaultPosition={{
            x: 0,
            y: (window.innerHeight * minimizedPosition.yPercent) / 100,
          }}
          position={{
            x: 0,
            y: (window.innerHeight * minimizedPosition.yPercent) / 100,
          }}
          onDrag={handleMinimizedDrag}
          bounds={{ top: 0, bottom: window.innerHeight - 60 }}
        >
          <Drawer
            anchor="right"
            open={minimized}
            variant="persistent"
            PaperProps={{
              style: {
                ...styles.minimizedWidget,
                position: "fixed",
                right: 10,
                cursor: "ns-resize", // Add a vertical resize cursor to indicate dragging
              },
            }}
          >
            <Tooltip title="Expand Context" placement="left">
              <IconButton onClick={handleExpand}>
                <ExpandMoreIcon style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </Drawer>
        </Draggable>
      )}
    </div>
  );
};

export default JitsiMeetComponent;
