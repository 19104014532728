import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import LanguagePreferences from "../components/LanguagePreferences";
import ServicePreferences from "../components/ServicePreferences";
import { getSCPByPhone } from "../controllers/scp";
import { useUser } from "../contexts/userContext";
import "./PreferencesLayout.css";
import CalendarComponent from "../components/Calendar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  updateSCPPreferences,
  addSCPAvailability,
  getScpAvailability,
  deleteSCPAvailability,
} from "../controllers/scp";
import { format, toZonedTime, fromZonedTime } from "date-fns-tz";
import moment from "moment";
import { supabase } from "../utils/supabase";
import Meetings from "../components/Meetings";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
  Grid,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";

const PersonalSchedulerLink = ({ userId }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `https://trayaschedule.hsciglobal.org/personal-scheduler?redirectUrl=${userId}`
      )
      .then(() => setCopySuccess(true))
      .catch((err) => console.error("Copy failed", err));
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false); // Reset state when the Snackbar is closed
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        padding: "12px 16px",
        borderRadius: "4px",
        border: "1px solid #ddd",
        cursor: "pointer",
        backgroundColor: "#fff",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);",
      }}
      onClick={handleCopyToClipboard} // Clicking anywhere on the Grid will copy
    >
      <Grid item xs>
        <Typography
          variant="h6"
          style={{
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: "Arial, sans-serif",
            color: "#333",
          }}
        >
          My Personal Scheduler Link
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title="Copy to Clipboard">
          <IconButton
            onClick={(e) => {
              e.stopPropagation(); // Prevents the Grid click from firing
              handleCopyToClipboard();
            }}
            style={{
              padding: "6px",
              fontSize: "16px",
            }}
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      </Grid>
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar} // Close Snackbar after autoHideDuration
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          severity="success"
          style={{
            color: "#000000",
            background: "#cfdcf3",
            fontSize: "14px",
            alignItems: "center",
            padding: "6px 16px",
          }}
        >
          <Typography variant="body2" style={{ marginRight: "8px" }}>
            Link copied to clipboard! Please forward this link to the intended
            user.
          </Typography>
        </Alert>
      </Snackbar>
    </Grid>
  );
};

const PreferencesLayout = () => {
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
  const [preferences, setPreferences] = useState({
    english: false,
    hindi: false,
    marathi: false,
    tamil: false,
    telugu: false,
    kannada: false,
    bengali: false,
  });
  const [services, setServices] = useState({
    "Compassionate Presence": false,
    "Hindu Tradition-related Matters": false,
    "Hindu Identity": false,
    "Yoga Therapy": false,
    Meditation: false,
    "Art Therapy": false,
    Senior: false,
    "Cancer Support": false,
    "Palliative and Hospice Care": false,
    "Trauma and Aged Care": false,
  });
  const [isNext, setIsNext] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();
  const { user, setUser } = useUser();
  const [events, setEvents] = useState([]);
  const [events2, setEvents2] = useState([]);

  const handleTimeZoneChange = (e) => {
    e.preventDefault();
    setSelectedTimeZone(e.target.value);
  };

  const convertToUTCTime = (date) => {
    const timeZone = "UTC";
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, "HH:mm", { timeZone });
  };

  const convertToUTCDate = (date) => {
    const timeZone = "UTC";
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, "yyyy-MM-dd", { timeZone });
  };

  const handleDateSelect = async (res) => {
    const startDate = new Date(res.start);
    const endDate = new Date(res.end);

    const formattedStart = fromZonedTime(startDate, selectedTimeZone);
    const formattedEnd = fromZonedTime(endDate, selectedTimeZone);

    const startUTC = convertToUTCTime(formattedStart);
    const endUTC = convertToUTCTime(formattedEnd);
    const dateUTC = convertToUTCDate(formattedStart);

    try {
      await addSCPAvailability(user.id, dateUTC, startUTC, endUTC);
      await getAvailability();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventClick = async (clickInfo, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteSCPAvailability(clickInfo.id);
        await getAvailability();
      } catch (err) {
        console.error(err);
      }
    } else return;
  };

  const handleLanguageCheckboxChange = (language) => {
    setPreferences({
      ...preferences,
      [language]: !preferences[language],
    });
  };

  const handleServiceCheckboxChange = (service) => {
    setServices({
      ...services,
      [service]: !services[service],
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    setIsNext(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const languagesSpoken = [];
    for (const language in preferences) {
      if (preferences[language]) {
        languagesSpoken.push(
          language.charAt(0).toUpperCase() + language.slice(1)
        );
      }
    }

    const servicesOffered = [];
    for (const service in services) {
      if (services[service]) {
        servicesOffered.push(service);
      }
    }

    updateSCPPreferences(
      user.id,
      languagesSpoken,
      servicesOffered,
      selectedTimeZone
    )
      .then(async (res) => {
        if (res) {
          setIsSubmitted(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const verifyUser = async () => {
    const params = queryString.parse(location.search);

    if (params.id) {
      const columnName = "preferences_link";
      const { data, error } = await supabase
        .from("scp")
        .select(`id, ${columnName}`);

      if (error) {
        console.error("Error fetching data from Supabase:", error);
      } else if (data && data.length > 0) {
        // Find the row where the JSON column contains the key
        const row = data.find((row) => row[columnName][params.id]);

        if (row) {
          getSCPByPhone(row[columnName][params.id]).then((res) => {
            setUser(res);
            if (res) {
              const languageUpdates = {};
              res.languages_spoken.forEach((lang) => {
                languageUpdates[lang.toLowerCase()] = true;
              });
              setPreferences((prev) => ({ ...prev, ...languageUpdates }));

              const serviceUpdates = {};
              res.services_offered.forEach((service) => {
                serviceUpdates[service] = true;
              });
              setServices((prev) => ({ ...prev, ...serviceUpdates }));
            }
          });
        } else {
          console.error("Key not found in any row's JSON object");
        }
      }
    }
  };

  useEffect(() => {
    verifyUser();
  }, [location, setUser]);

  const getAvailability = async () => {
    const res = await getScpAvailability(user.id);

    let initialData = res.map((row) => {
      const [hourStart, minuteStart] = row.start_time.split(":").map(Number);
      const [hourEnd, minuteEnd] = row.end_time.split(":").map(Number);
      const [year, month, day] = row.date.split("-").map(Number);

      // 1. Create UTC Date objects for start and end
      const startDateTime = new Date(
        Date.UTC(year, month - 1, day, hourStart, minuteStart)
      );

      let endDateTime;
      if (
        hourStart < hourEnd ||
        (hourStart === hourEnd && minuteStart < minuteEnd)
      ) {
        // End time is on the same day
        endDateTime = new Date(
          Date.UTC(year, month - 1, day, hourEnd, minuteEnd)
        );
      } else {
        // End time is on the next day
        endDateTime = new Date(
          Date.UTC(year, month - 1, day + 1, hourEnd, minuteEnd)
        );
      }

      return {
        id: row.id,
        start: startDateTime,
        end: endDateTime,
      };
    });

    setEvents(initialData);
  };

  useEffect(() => {
    const updatedEvents = events.map((event) => {
      let convertedStart = toZonedTime(event.start, selectedTimeZone);
      let convertedEnd = toZonedTime(event.end, selectedTimeZone);

      return {
        ...event,
        start: convertedStart,
        end: convertedEnd,
      };
    });

    setEvents2(updatedEvents);
  }, [selectedTimeZone, events]);

  useEffect(() => {
    if (user && user.id) {
      getAvailability(user.id);
    }
  }, [user]);

  return user ? (
    !isNext ? (
      <div className="preferences-container">
        <span style={{ fontSize: "25px", fontWeight: "bold" }}>
          Welcome, {user.name} ji.
        </span>
        <Box
          sx={{
            margin: "20px auto",
            maxWidth: "800px",
            width: "100%",
            padding: "0 16px",
            boxSizing: "border-box",
          }}
        >
          <Grid container spacing={2} md={12}>
            <Grid item xs={12} md={6}>
              <LanguagePreferences
                preferences={preferences}
                handleCheckboxChange={handleLanguageCheckboxChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ServicePreferences
                services={services}
                handleCheckboxChange={handleServiceCheckboxChange}
              />
            </Grid>
          </Grid>
          <br />
          <Meetings user={user} />
          <br />
          <PersonalSchedulerLink userId={user.id} />
          <br />
          <Grid
            container
            spacing={2}
            sx={{ width: "100%" }}
            alignItems={"center"}
          >
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() => {
                  window.open(
                    "https://trayaschedule.hsciglobal.org/book-all?id=" +
                      new URLSearchParams(window.location.search).get("id") +
                      "&role=scp",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
                fullWidth
                sx={{
                  textTransform: "none",
                  fontSize: { xs: "14px", sm: "16px" },
                  boxShadow:
                    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);",
                  display: "flex",
                  justifyContent: "space-between", // Align icon to the right
                  alignItems: "center",
                }}
              >
                Meet Anyone
                <OpenInNewIcon sx={{ fontSize: "18px", marginLeft: "8px" }} />
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={handleNext}
                fullWidth
                sx={{
                  textTransform: "none",
                  fontSize: { xs: "14px", sm: "16px" },
                  boxShadow:
                    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);",
                }}
              >
                Update Availability
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    ) : isSubmitted ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f4f4f9",
          padding: "20px",
          boxSizing: "border-box",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#333",
            textAlign: "center",
          }}
        >
          Your responses have been recorded, {user.name} Ji!{" "}
          <a
            href={"https://wa.me/19252305898"}
            style={{ color: "#4CAF50", textDecoration: "none" }}
          >
            Click here
          </a>{" "}
          to go back to WhatsApp.
        </span>
      </div>
    ) : (
      <CalendarComponent
        handleDateSelect={handleDateSelect}
        handleEventClick={handleEventClick}
        setIsNext={setIsNext}
        selectedTimeZone={selectedTimeZone}
        handleSubmit={handleSubmit}
        events={events2}
        handleTimeZoneChange={handleTimeZoneChange}
      />
    )
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f4f4f9",
        color: "#333",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <span style={{ fontSize: "24px", textAlign: "center" }}>Loading...</span>
    </div>
  );
};

export default PreferencesLayout;
