import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  OutlinedInput,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { supabase } from "../utils/supabase";
import moment from "moment";

const FilterScpDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [languages, setLanguages] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scpData, setScpData] = useState([]);
  const [convertedScpData, setConvertedScpData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());

  const timezones = moment.tz.names();

  const handleTimeZoneChange = async (e) => {
    const newTimeZone = e.target.value;
    setSelectedTimeZone(newTimeZone);

    if (scpData.length > 0) {
      await convertTimezones(scpData, newTimeZone);
    }
  };

  const convertTimezones = async (data, targetTimeZone) => {
    try {
      if (!data || data.length === 0) return;

      const timesToConvert = data.map((item) => ({
        id: item.id,
        start_time: `${item.date}T${item.from}`,
        end_time: `${item.date}T${item.to}`,
      }));

      const response = await fetch(
        "https://backend.trayaschedule.hsciglobal.org/convert-timezone",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            times: timesToConvert,
            currentTimeZone: "UTC",
            targetTimeZone,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Timezone conversion failed");
      }

      const convertedTimes = await response.json();

      const transformedData = data.map((item) => {
        const convertedItem = convertedTimes.find((t) => t.id === item.id);
        return {
          ...item,
          date: convertedItem.start_time.date,
          from: convertedItem.start_time.time,
          to: convertedItem.end_time.time,
          originalDate: item.date,
          originalFrom: item.from,
          originalTo: item.to,
        };
      });

      setConvertedScpData(transformedData);
    } catch (err) {
      console.error("Error converting timezones:", err);
      // Fallback to original data if conversion fails
      setConvertedScpData(data);
    }
  };

  const languageOptions = [
    "English",
    "Hindi",
    "Marathi",
    "Tamil",
    "Telugu",
    "Kannada",
    "Bengali",
  ];

  const serviceOptions = [
    "Compassionate Presence",
    "Hindu Tradition-related Matters",
    "Hindu Identity",
    "Yoga Therapy",
    "Meditation",
    "Art Therapy",
    "Senior",
    "Cancer Support",
    "Palliative and Hospice Care",
    "Trauma and Aged Care",
  ];

  useEffect(() => {
    if (languages.length > 0 || services.length > 0) {
      fetchSCPDetails();
    } else {
      setScpData([]);
      setConvertedScpData([]);
    }
  }, [languages, services]);

  const fetchSCPDetails = async () => {
    setLoading(true);
    setError(null);

    try {
      // 1. First get SCPs that match ALL filters
      let scpQuery = supabase
        .from("scp")
        .select("id")
        .order("name", { ascending: true });

      if (languages.length > 0) {
        scpQuery = scpQuery.contains("languages_spoken", languages);
      }

      if (services.length > 0) {
        scpQuery = scpQuery.contains("services_offered", services);
      }

      const { data: matchingScps, error: scpError } = await scpQuery;
      if (scpError) throw scpError;

      if (!matchingScps || matchingScps.length === 0) {
        setScpData([]);
        setConvertedScpData([]);
        return;
      }

      // 2. Get FUTURE availability for matching SCPs
      const today = new Date().toISOString().split("T")[0];
      const scpIds = matchingScps.map((scp) => scp.id);

      const { data: availabilityData, error: availabilityError } =
        await supabase
          .from("scp_availability")
          .select(
            `
          id,
          date,
          start_time,
          end_time,
          scp:scp_id (
            id,
            name
          )
        `
          )
          .in("scp_id", scpIds)
          .gte("date", today)
          .order("date", { ascending: true })
          .order("start_time", { ascending: true });

      if (availabilityError) throw availabilityError;

      // Additional filtering for today's availabilities that haven't ended yet
      const now = new Date();
      const currentTime = now.toTimeString().substring(0, 8);

      const filteredData = availabilityData.filter((availability) => {
        if (availability.date === today) {
          return availability.end_time > currentTime;
        }
        return true;
      });

      // Transform data
      const transformedData = filteredData.map((availability) => ({
        id: availability.id,
        date: availability.date,
        from: availability.start_time,
        to: availability.end_time,
        scpName: availability.scp.name,
        originalDate: availability.date,
        originalFrom: availability.start_time,
        originalTo: availability.end_time,
      }));

      setScpData(transformedData);
      await convertTimezones(transformedData, selectedTimeZone);
    } catch (err) {
      console.error("Error fetching SCP data:", err);
      setError("Failed to load data. Please try again.");
      setScpData([]);
      setConvertedScpData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (value, type, event) => {
    event.stopPropagation();
    if (type === "language") {
      setLanguages(languages.filter((lang) => lang !== value));
    } else {
      setServices(services.filter((service) => service !== value));
    }
  };

  const renderSelected = (selected, type) => {
    if (selected.length === 0) {
      return (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ ml: 1, py: 1.5 }}
        >
          {`No ${type} selected`}
        </Typography>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          maxHeight: "48px",
          overflowY: "auto",
          alignItems: "center",
          py: 1,
          px: 1,
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "2px",
          },
        }}
      >
        {selected.map((value) => (
          <Chip
            key={value}
            label={value}
            onMouseDown={(e) => e.stopPropagation()}
            onDelete={(e) => handleDelete(value, type, e)}
            deleteIcon={<Clear />}
            size="small"
            sx={{
              "& .MuiChip-label": {
                whiteSpace: "nowrap",
                px: 1,
              },
            }}
          />
        ))}
      </Box>
    );
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MMM D, YYYY");
  };

  const formatTime = (timeString) => {
    return moment(timeString, "HH:mm:ss").format("h:mm A");
  };

  return (
    <Box sx={{ p: isMobile ? 2 : 3 }}>
      <Grid container spacing={2}>
        {/* Filters Row */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Timezone Selector */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="timezone-select-label">
                  Your Timezone
                </InputLabel>
                <Select
                  labelId="timezone-select-label"
                  id="timezone-select"
                  value={selectedTimeZone}
                  label="Your Timezone"
                  onChange={handleTimeZoneChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  sx={{
                    height: "56px",
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  {timezones.map((tz) => (
                    <MenuItem key={tz} value={tz}>
                      <Typography variant="body2">{tz}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Language Dropdown */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel
                  shrink
                  sx={{
                    backgroundColor: "background.paper",
                    px: 0.5,
                    ml: -1.4,
                    fontSize: "0.875rem",
                  }}
                >
                  Language Preferences
                </InputLabel>
                <Select
                  multiple
                  value={languages}
                  onChange={(e) => setLanguages(e.target.value)}
                  input={<OutlinedInput notched label="Language Preferences" />}
                  renderValue={(selected) =>
                    renderSelected(selected, "language")
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                      },
                    },
                  }}
                  sx={{
                    height: "56px",
                    "& .MuiSelect-select": {
                      minHeight: "auto !important",
                      py: 1.5,
                    },
                  }}
                >
                  {languageOptions.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                      <Typography variant="body2">{lang}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Service Dropdown */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel
                  shrink
                  sx={{
                    backgroundColor: "background.paper",
                    px: 0.5,
                    ml: -1.4,
                    fontSize: "0.875rem",
                  }}
                >
                  Service Preferences
                </InputLabel>
                <Select
                  multiple
                  value={services}
                  onChange={(e) => setServices(e.target.value)}
                  input={<OutlinedInput notched label="Service Preferences" />}
                  renderValue={(selected) =>
                    renderSelected(selected, "service")
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                      },
                    },
                  }}
                  sx={{
                    height: "56px",
                    "& .MuiSelect-select": {
                      minHeight: "auto !important",
                      py: 1.5,
                    },
                  }}
                >
                  {serviceOptions.map((service) => (
                    <MenuItem key={service} value={service}>
                      <Typography variant="body2">{service}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Results Section */}
        <Grid item xs={12}>
          {error && (
            <Box textAlign="center" mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>
          )}

          {loading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress size={24} />
            </Box>
          ) : convertedScpData.length > 0 ? (
            <TableContainer
              component={Paper}
              elevation={2}
              sx={{
                mt: 2,
                width: "100%",
                overflowX: "auto",
              }}
            >
              <Table sx={{ minWidth: isMobile ? 600 : "auto" }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 120 }}>
                      <Typography variant="subtitle2">Date</Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: 100 }}>
                      <Typography variant="subtitle2">From</Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: 100 }}>
                      <Typography variant="subtitle2">To</Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: 150 }}>
                      <Typography variant="subtitle2">SCP Name</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {convertedScpData.map((row) => (
                    <TableRow key={row.id} hover>
                      <TableCell sx={{ minWidth: 120 }}>
                        <Typography variant="body2" noWrap>
                          {formatDate(row.date)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: 100 }}>
                        <Typography variant="body2" noWrap>
                          {formatTime(row.from)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: 100 }}>
                        <Typography variant="body2" noWrap>
                          {formatTime(row.to)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ minWidth: 150 }}>
                        <Typography variant="body2" noWrap>
                          {row.scpName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              textAlign="center"
              p={4}
              border={1}
              borderColor="divider"
              borderRadius={1}
              sx={{ mt: 2 }}
            >
              <Typography variant="body2" color="text.secondary">
                {languages.length === 0 && services.length === 0
                  ? "Select language and/or service filters to see results"
                  : "No available SCPs match the selected filters"}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterScpDashboard;
