import React, { useState, useEffect } from "react";
import {
  getScpAvailabilityToday,
  getScpAvailabilityThisWeek,
  getScpAvailabilityThisMonth,
} from "../controllers/analytics";
import {
  MenuItem,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { DateTime } from "luxon";
import moment from "moment";
import axios from "axios";

const BookKCAvailability = ({ student }) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
  const [availabilities, setAvailabilities] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeRange, setTimeRange] = useState("week");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const timezones = moment.tz.names();

  useEffect(() => {
    async function fetchData() {
      let data = [];

      switch (timeRange) {
        case "today":
          data = await getScpAvailabilityToday(34);
          break;
        case "week":
          data = await getScpAvailabilityThisWeek(34);
          break;
        case "month":
          data = await getScpAvailabilityThisMonth(34);
          break;
        default:
          break;
      }

      const modifiedData = data.map((item) => {
        const [year, month, day] = item.date.split("-").map(Number);
        const [startHour, startMinute] = item.start_time.split(":").map(Number);
        const [endHour, endMinute] = item.end_time.split(":").map(Number);

        // Create start datetime in UTC
        const startDateTime = new Date(
          Date.UTC(year, month - 1, day, startHour, startMinute)
        );

        // Create end datetime in UTC
        let endDateTime = new Date(
          Date.UTC(year, month - 1, day, endHour, endMinute)
        );

        // Check if end time is earlier than start time, indicating spillover to next day
        if (endDateTime < startDateTime) {
          endDateTime.setUTCDate(endDateTime.getUTCDate() + 1);
        }

        // Format the modified item
        return {
          id: item.id,
          scp_id: item.scp_id,
          date: item.date,
          start_time: startDateTime.toISOString(),
          end_time: endDateTime.toISOString(),
          created_at: item.created_at,
          scp: item.scp,
        };
      });

      return modifiedData;
    }

    async function convertTimezones(requestBody) {
      try {
        const response = await fetch(
          "https://backend.trayaschedule.hsciglobal.org/convert-timezone",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        return await response.json();
      } catch (error) {
        console.error("Error:", error);
        return null;
      }
    }

    async function processData() {
      setIsLoading(true);
      try {
        // Fetch the initial data
        const modData = await fetchData();

        // Build the request body for timezone conversion
        const requestBody = {
          times: modData.map((item) => ({
            id: item.id,
            start_time: item.start_time,
            end_time: item.end_time,
          })),
          currentTimeZone: "UTC",
          targetTimeZone: selectedTimeZone,
        };

        // Call the API to convert timezones
        const convertedTimes = await convertTimezones(requestBody);

        if (convertedTimes) {
          let today = new Date().toISOString();

          // Map the converted times back to the original data
          const updatedData = modData.map((item) => {
            const convertedItem = convertedTimes.find(
              (ct) => ct.id === item.id
            );
            if (convertedItem) {
              return {
                ...item,
                converted_start_time: convertedItem.start_time,
                converted_end_time: convertedItem.end_time,
              };
            }
            return item;
          });

          // Filter out past availabilities
          const filteredData = updatedData.filter((availability) => {
            const availabilityDateTime = new Date(
              availability.start_time
            ).toISOString();
            return availabilityDateTime >= today;
          });

          let finalData = filteredData.sort((a, b) => {
            const dateA = new Date(a.start_time);
            const dateB = new Date(b.start_time);
            return dateA - dateB;
          });

          setAvailabilities(finalData);
        }
      } catch (error) {
        console.error("Error processing data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    processData();
  }, [selectedTimeZone, timeRange]);

  const handleTimeZoneChange = (e) => {
    e.preventDefault();
    setSelectedTimeZone(e.target.value);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MMMM Do, YYYY");
  };

  const generateTimeSlots = (startTime, endTime) => {
    let start = moment(startTime, "HH:mm");
    let end = moment(endTime, "HH:mm");
    let slots = [];

    // If end time is before start time, assume it's on the next day
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    while (start.isBefore(end)) {
      let nextSlot = moment(start).add(30, "minutes");
      if (nextSlot.isAfter(end)) nextSlot = moment(end);
      slots.push(`${start.format("HH:mm")} - ${nextSlot.format("HH:mm")}`);
      start.add(30, "minutes");
    }
    return slots;
  };

  const handleBookClick = (availabilityRow, availability) => {
    setSelectedAvailability(availabilityRow);
    const slots = generateTimeSlots(
      availability.split("-")[0],
      availability.split("-")[1]
    );
    setTimeSlots(slots);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSubmitting(false);
    setSelectedAvailability(null);
    setTimeSlots([]);
    setSelectedSlot("");
  };

  const handleSlotSelection = (event) => {
    setSelectedSlot(event.target.value);
  };

  const formatTimeToUTC = (timeString, dateString, tz) => {
    const dateTimeString = `${dateString}T${timeString}:00`;

    const localDateTime = DateTime.fromISO(dateTimeString, { zone: tz });
    const utcDateTime = localDateTime.setZone("UTC");

    return utcDateTime.toFormat("HH:mm");
  };

  const handleConfirmBooking = async () => {
    if (selectedSlot) {
      setIsSubmitting(true);

      let startTime = formatTimeToUTC(
        selectedSlot.split(" - ")[0],
        selectedAvailability.converted_start_time.date,
        selectedTimeZone
      );
      let endTime = formatTimeToUTC(
        selectedSlot.split(" - ")[1],
        selectedAvailability.converted_end_time.date,
        selectedTimeZone
      );

      let meetingDate =
        selectedAvailability.converted_start_time.time.split(":")[0] >
        selectedSlot.split(" - ")[0].split(":")[0]
          ? selectedAvailability.converted_end_time.date
          : selectedAvailability.converted_start_time.date;

      let dataJson = {
        scp_id: 34,
        scp_name: "Kailash Joshi",
        scp_phone: "14084645172",
        scp_timezone: "America/Los_Angeles",
        student_id: student.id,
        patient_name: student.name,
        patient_phone: student.whatsapp_phone_no,
        patient_timezone: selectedTimeZone,
        meeting_date: meetingDate,
        start_time: startTime,
        end_time: endTime,
      };

      try {
        const response = await axios.post(
          "https://backend.trayaschedule.hsciglobal.org/create-student-appointment",
          dataJson
        );

        if (response.status === 200) {
          setIsSubmitting(false);
          setIsSuccess(true);
          console.log("Booking confirmed:", response.data);
          handleDialogClose();
        } else {
          setIsSubmitting(false);
          console.error("Booking failed with status:", response.status);
        }
      } catch (error) {
        setIsSubmitting(false);
        console.error("An error occurred during booking:", error);
      }
    } else {
      console.error("No slot selected, booking not attempted.");
    }
  };

  return isSuccess ? (
    <Typography
      variant="h6"
      sx={{
        fontSize: "18px",
        fontWeight: "bold",
        color: "green",
        textAlign: "center",
        marginTop: "25px",
      }}
    >
      Your appointment has been successfully booked. We will contact you on
      Whatsapp shortly. Click{" "}
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setIsSuccess(false);
        }}
      >
        here
      </span>{" "}
      to return to the main page!
    </Typography>
  ) : (
    <Box sx={{ padding: "16px" }}>
      <div
        style={{
          marginBottom: "10px",
          padding: "10px",
        }}
      >
        <select
          id="timezone-select"
          value={selectedTimeZone}
          onChange={handleTimeZoneChange}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            fontSize: "14px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            transition: "border-color 0.3s ease-in-out",
          }}
          onMouseOver={(e) => (e.target.style.borderColor = "#888")}
          onMouseOut={(e) => (e.target.style.borderColor = "#ccc")}
          onFocus={(e) => (e.target.style.borderColor = "#888")}
          onBlur={(e) => (e.target.style.borderColor = "#ccc")}
        >
          {timezones.map((tz) => (
            <option
              key={tz}
              value={tz}
              style={{
                fontFamily: "Arial, sans-serif",
              }}
            >
              {tz}
            </option>
          ))}
        </select>
      </div>
      <Typography variant="h6" gutterBottom>
        Kailash Joshi ji's Schedule
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "stretch" : "center",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        <Select
          defaultValue={"week"}
          onChange={(e) => setTimeRange(e.target.value)}
          displayEmpty
          sx={{ width: isMobile ? "100%" : "150px" }}
        >
          <MenuItem value="" disabled>
            Select Timeframe
          </MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="week">This Week</MenuItem>
          <MenuItem value="month">This Month</MenuItem>
        </Select>
      </Box>
      {availabilities.length > 0 && !isLoading ? (
        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", overflowX: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Slot</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availabilities.map((availability) => (
                <TableRow key={availability.id}>
                  <TableCell>
                    {formatDate(availability.converted_start_time?.date)}
                  </TableCell>
                  <TableCell>
                    {availability.converted_start_time?.time.slice(0, 5)} -{" "}
                    {availability.converted_end_time?.time.slice(0, 5)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleBookClick(
                          availability,
                          `${availability.converted_start_time?.time.slice(
                            0,
                            5
                          )}-${availability.converted_end_time?.time.slice(
                            0,
                            5
                          )}`
                        )
                      }
                      sx={{ width: isMobile ? "100%" : "auto" }}
                    >
                      Book
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>Loading...</Typography>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Select a Time Slot</DialogTitle>
        <DialogContent
          sx={{
            textAlign: "center",
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="time-slot"
              name="time-slot-group"
              value={selectedSlot}
              onChange={handleSlotSelection}
            >
              {timeSlots.map((slot, index) => (
                <FormControlLabel
                  key={index}
                  value={slot}
                  control={<Radio />}
                  label={slot}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmBooking}
            variant="contained"
            disabled={!selectedSlot || isSubmitting}
          >
            Confirm
          </Button>
          <Button onClick={handleDialogClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookKCAvailability;
