import React, { useState, useEffect } from "react";
import {
  getScpAvailabilityToday,
  getScpAvailabilityComingWeek,
  getScpAvailabilityComingMonth,
} from "../../controllers/analytics";
import {
  TextField,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  MenuItem,
  Select,
  Button,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Chip,
  Divider,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { getAllSCPs } from "../../controllers/scp";
import moment from "moment";
import { DateTime } from "luxon";
import axios from "axios";
import { supabase } from "../../utils/supabase";
import { format, parse } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

const SearchOmnibus = ({
  selectedTimeZone,
  student,
  setIsSuccess,
  userRole,
  scholar,
}) => {
  // Existing state variables
  const [timeRange, setTimeRange] = useState("nweek");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [lastScpId, setLastScpId] = useState(null);
  const [showAvailabilities, setShowAvailabilities] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // New state variables for match finding
  const [showMatchFinder, setShowMatchFinder] = useState(false);
  const [selectedScps, setSelectedScps] = useState([]);
  const [maxMeetings, setMaxMeetings] = useState(1);
  const [dateRange, setDateRange] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().add(1, "week").format("YYYY-MM-DD"),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Keep all existing useEffect hooks and functions
  useEffect(() => {
    const getLastScpId = async () => {
      if (!student?.id || !scholar) return;

      const { data, error } = await supabase
        .from("appointments")
        .select("scp_id")
        .eq("student_id", student.id)
        .order("meeting_date", { ascending: false })
        .limit(1)
        .single();

      if (error) {
        console.error("Error fetching last SCP:", error.message);
        return;
      }

      if (data) {
        setLastScpId(data.scp_id);
      }
    };

    getLastScpId();
  }, [student, scholar]);

  useEffect(() => {
    getAllSCPs().then((res) => {
      const usersArray = res
        .map((user) => ({
          id: user.id,
          name: user.name,
          phone: user.whatsapp_phone_no,
          timezone: user.timezone,
        }))
        .filter(
          (user) =>
            !scholar ||
            user.id === 28 ||
            user.id === 34 ||
            user.id === 41 ||
            user.id === 51 ||
            user.id === 53
        );
      setUsers(usersArray);
    });
  }, [scholar]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const handleUserChange = (event) => {
    const selectedUserTemp = event.target.value;
    setSelectedUserDetails(selectedUserTemp);
    setSelectedUser(selectedUserTemp.id); // Add this line
  };

  // Initialize selectedUserDetails with the default user when lastScpId changes
  useEffect(() => {
    if (lastScpId && users.length > 0) {
      const defaultUser = users.find((user) => user.id === lastScpId);
      if (defaultUser) {
        setSelectedUserDetails(defaultUser);
        setSelectedUser(defaultUser.id); // Add this line
      }
    }
  }, [lastScpId, users]);

  useEffect(() => {
    console.log("<--- WE ARE HERE ---->");
    if (!selectedUser) {
      return;
    } else {
      async function fetchData() {
        let data = [];

        switch (timeRange) {
          case "today":
            data = await getScpAvailabilityToday(
              selectedUser,
              selectedTimeZone
            );
            break;
          case "nweek":
            data = await getScpAvailabilityComingWeek(
              selectedUser,
              selectedTimeZone
            );
            break;
          case "nmonth":
            data = await getScpAvailabilityComingMonth(
              selectedUser,
              selectedTimeZone
            );
            break;
          default:
            break;
        }

        // return modifiedData;
        const modifiedData = data.map((item) => {
          // Parse date and time components
          const [year, month, day] = item.date.split("-").map(Number);
          const [startHour, startMinute] = item.start_time
            .split(":")
            .map(Number);
          const [endHour, endMinute] = item.end_time.split(":").map(Number);

          // Create start datetime in UTC
          const startDateTime = new Date(
            Date.UTC(year, month - 1, day, startHour, startMinute)
          );

          // Create end datetime in UTC
          let endDateTime = new Date(
            Date.UTC(year, month - 1, day, endHour, endMinute)
          );

          // Check if end time is earlier than start time, indicating spillover to next day
          if (endDateTime < startDateTime) {
            // If end time is before start time, it means it's on the next day
            endDateTime = new Date(
              Date.UTC(year, month - 1, day + 1, endHour, endMinute)
            );
          }

          // Format the modified item
          return {
            id: item.id,
            scp_id: item.scp_id,
            date: item.date,
            start_time: startDateTime.toISOString(),
            end_time: endDateTime.toISOString(),
            created_at: item.created_at,
            scp: item.scp,
          };
        });

        return modifiedData;
      }

      async function convertTimezones(requestBody) {
        try {
          const response = await fetch(
            "https://backend.trayaschedule.hsciglobal.org/convert-timezone",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );
          return await response.json();
        } catch (error) {
          console.error("Error:", error);
          return null;
        }
      }

      async function processData() {
        setIsLoading(true);
        try {
          const modData = await fetchData();

          const requestBody = {
            times: modData.map((item) => ({
              id: item.id,
              start_time: item.start_time,
              end_time: item.end_time,
            })),
            currentTimeZone: "UTC",
            targetTimeZone: selectedTimeZone,
          };

          const convertedTimes = await convertTimezones(requestBody);

          if (convertedTimes) {
            // Get current date and time in user's timezone
            const userNow = new Date().toLocaleString("en-US", {
              timeZone: selectedTimeZone,
            });
            const now = new Date(userNow);

            // Get just the date part for today in user's timezone
            const todayDate = new Date(now).setHours(0, 0, 0, 0);

            const updatedData = modData.map((item) => {
              const convertedItem = convertedTimes.find(
                (ct) => ct.id === item.id
              );
              if (convertedItem) {
                return {
                  ...item,
                  converted_start_time: convertedItem.start_time,
                  converted_end_time: convertedItem.end_time,
                };
              }
              return item;
            });

            // Filter availabilities
            const filteredData = updatedData.filter((availability) => {
              const availabilityDateTime = new Date(
                availability.converted_start_time.convertedDate
              );
              const availabilityDate = new Date(availabilityDateTime).setHours(
                0,
                0,
                0,
                0
              );

              // If it's a future date, include it
              if (availabilityDate > todayDate) {
                return timeRange === "today" ? false : true;
              }

              // If it's today, check the time
              if (availabilityDate === todayDate) {
                return availabilityDateTime >= now;
              }

              return false;
            });

            let finalData = filteredData.sort((a, b) => {
              const dateA = new Date(a.converted_start_time.convertedDate);
              const dateB = new Date(b.converted_start_time.convertedDate);
              return dateA - dateB;
            });

            setAvailabilities(finalData);
          }
        } catch (error) {
          console.error("Error processing data:", error);
        } finally {
          setIsLoading(false);
        }
      }

      processData();
    }
  }, [selectedTimeZone, timeRange, selectedUser]);

  console.log("selectedUser: ", selectedUser);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsSubmitting(false);
    setSelectedAvailability(null);
    setTimeSlots([]);
    setSelectedSlot("");
  };

  const handleSlotSelection = (event) => {
    setSelectedSlot(event.target.value);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MMMM Do, YYYY");
  };

  const formatTimeToUTC = (timeString, dateString, tz) => {
    const dateTimeString = `${dateString}T${timeString}:00`;

    // Convert from local timezone to UTC
    const localDateTime = DateTime.fromISO(dateTimeString, { zone: tz });
    const utcDateTime = localDateTime.toUTC();

    return utcDateTime.toISO(); // Returns full ISO datetime string
  };

  const generateTimeSlots = (startTime, endTime) => {
    let start = moment(startTime, "HH:mm");
    let end = moment(endTime, "HH:mm");
    let slots = [];

    // If end time is before start time, assume it's on the next day
    if (end.isBefore(start)) {
      end.add(1, "day");
    }

    while (start.isBefore(end)) {
      let nextSlot = moment(start).add(30, "minutes");
      if (nextSlot.isAfter(end)) nextSlot = moment(end);
      slots.push(`${start.format("HH:mm")} - ${nextSlot.format("HH:mm")}`);
      start.add(30, "minutes");
    }
    return slots;
  };

  const handleBookClick = (availabilityRow, availability) => {
    setSelectedAvailability(availabilityRow);
    const slots = generateTimeSlots(
      availability.split("-")[0],
      availability.split("-")[1]
    );
    setTimeSlots(slots);
    setOpenDialog(true);
  };

  function calculateMeetingDate(
    selectedSlot,
    selectedAvailability,
    selectedTimeZone
  ) {
    let startTimeUTC = formatTimeToUTC(
      selectedSlot.split(" - ")[0], // Extracts "00:30"
      selectedAvailability.converted_start_time.date, // "2025-04-01"
      selectedTimeZone
    );
    let endTimeUTC = formatTimeToUTC(
      selectedSlot.split(" - ")[1], // Extracts "01:00"
      selectedAvailability.converted_end_time.date, // "2025-04-01"
      selectedTimeZone
    );

    // Ensure `formatTimeToUTC()` returned a valid ISO datetime
    if (!startTimeUTC.includes("T") || !startTimeUTC.endsWith("Z")) {
      throw new Error(
        `Invalid startTimeUTC: Expected ISO datetime, got ${startTimeUTC}`
      );
    }
    if (!endTimeUTC.includes("T") || !endTimeUTC.endsWith("Z")) {
      throw new Error(
        `Invalid endTimeUTC: Expected ISO datetime, got ${endTimeUTC}`
      );
    }

    // Convert to Date objects
    let userStartUTC = new Date(startTimeUTC);
    let userEndUTC = new Date(endTimeUTC);
    let blockStartUTC = new Date(selectedAvailability.start_time);
    let blockEndUTC = new Date(selectedAvailability.end_time);

    if (
      isNaN(userStartUTC.getTime()) ||
      isNaN(userEndUTC.getTime()) ||
      isNaN(blockStartUTC.getTime()) ||
      isNaN(blockEndUTC.getTime())
    ) {
      throw new Error("Invalid date values detected");
    }

    // Determine correct meeting date
    let blockStartDateUTC = blockStartUTC.toISOString().split("T")[0];
    let userStartDateUTC = userStartUTC.toISOString().split("T")[0];

    let meetingDate = blockStartDateUTC; // Default to block's start date

    // If the user-selected start time is past midnight, adjust the meeting date
    if (userStartUTC >= blockEndUTC) {
      let nextDay = new Date(
        Date.UTC(
          blockStartUTC.getUTCFullYear(),
          blockStartUTC.getUTCMonth(),
          blockStartUTC.getUTCDate() + 1
        )
      );
      meetingDate = nextDay.toISOString().split("T")[0];
    } else if (userStartDateUTC !== blockStartDateUTC) {
      meetingDate = userStartDateUTC;
    }

    return meetingDate;
  }

  const handleConfirmBooking = async () => {
    if (selectedSlot) {
      setIsSubmitting(true);

     let startTime = formatTimeToUTC(
        selectedSlot.split(" - ")[0],
        selectedAvailability.converted_start_time.date,
        selectedTimeZone
      );
      let endTime = formatTimeToUTC(
        selectedSlot.split(" - ")[1],
        selectedAvailability.converted_end_time.date,
        selectedTimeZone
      );

      let meetingDate = calculateMeetingDate(
        selectedSlot,
        selectedAvailability,
        selectedTimeZone
      );

      let dataJson = {
        scp_id: selectedUser,
        scp_name: selectedUserDetails.name,
        scp_phone: selectedUserDetails.phone,
        scp_timezone: selectedUserDetails.timezone,
        [userRole === "student" ? "student_id" : "patient_id"]: student.id,
        patient_name: student.name,
        patient_phone: student.whatsapp_phone_no,
        patient_timezone: selectedTimeZone,
        meeting_date: meetingDate,
        start_time: startTime.split("T")[1].slice(0, 5),
        end_time: endTime.split("T")[1].slice(0, 5),
      };

      try {
        const response = await axios.post(
          "https://backend.trayaschedule.hsciglobal.org/create-student-appointment",
          dataJson
        );

        if (response.status === 200) {
          console.log("Booking confirmed:", response.data);
        } else {
          setIsSubmitting(false);
          console.error("Booking failed with status:", response.status);
        }

        const table = userRole === "student" ? "students" : "scp";
        const { data, error } = await supabase
          .from(table)
          .update({
            timezone: selectedTimeZone,
          })
          .eq("id", student.id)
          .select();

        if (error) {
          console.error("Supabase update error:", error.message);
          throw error;
        }

        if (data && data.length > 0) {
          console.log("Update Successful!");
          setIsSubmitting(false);
          setIsSuccess(true);
          handleDialogClose();
        } else {
          setIsSubmitting(false);
          console.error("No records updated.");
        }
      } catch (error) {
        setIsSubmitting(false);
        console.error("An error occurred during booking or updating:", error);
      }
    } else {
      console.error("No slot selected, booking not attempted.");
    }
  };

  // Add new functions for match finding
  const handleScpSelection = (scpId) => {
    setSelectedScps((prev) =>
      prev.includes(scpId)
        ? prev.filter((id) => id !== scpId)
        : [...prev, scpId]
    );
  };

  const handleFindMatches = async () => {
    setIsSubmitting(true); // Indicate submission

    try {
      setIsLoading(true);

      // Populate the matched_scps table
      const matchedScpsData = selectedScps.flatMap((matchedScpId) =>
        Array(maxMeetings)
          .fill()
          .map(() => ({
            scp_id: student.id,
            matched_scp_id: matchedScpId,
            from_date: dateRange.from,
            to_date: dateRange.to,
          }))
      );

      // Insert the data into the matched_scps table
      const { data: insertedMatches, error: insertError } = await supabase
        .from("matched_scps")
        .insert(matchedScpsData)
        .select();

      if (insertError) throw insertError;

      // Proceed with finding overlapping meetings
      const response = await axios.post(
        "https://backend.trayaschedule.hsciglobal.org/find-overlapping-meetings",
        {
          given_scp_id: student.id,
          scp_list: selectedScps,
          from_date: dateRange.from,
          to_date: dateRange.to,
          max_meetings: maxMeetings,
        }
      );

      console.log("response.data: ", response.data);

      if (!response.data.error && response.data) {
        const appointmentsData = response.data.map((match) => ({
          patient_id: student.id,
          scp_id: match.scp_id,
          patient_name: student.name,
          scp_name: match.scp_name,
          patient_phone: student.whatsapp_phone_no,
          scp_phone: match.scp_phone,
          patient_timezone: student.timezone,
          scp_timezone: match.scp_timezone,
          meeting_date: match.date,
          start_time: match.start_time,
          end_time: match.end_time,
        }));

        // Book meetings for each appointment
        const bookingPromises = appointmentsData?.map((appointment) =>
          axios.post(
            "https://backend.trayaschedule.hsciglobal.org/book-omnibus-meetings",
            appointment
          )
        );

        // Execute all booking promises
        const bookingResults = await Promise.all(bookingPromises);
        console.log("Booking results:", bookingResults);

        setSnackbarMessage(
          "Matches found and bookings completed successfully!"
        );
        setIsSubmitting(false);
      } else {
        setSnackbarMessage(response.data.error);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error in the matching process:", error);
      setSnackbarMessage("An error occurred while finding matches.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (snackbarMessage) {
      setSnackbarOpen(true); // Trigger Snackbar when the message updates
    }
  }, [snackbarMessage]);

  // Modify the return statement to include the new section
  // return (
  //   <Box
  //     sx={{
  //       padding: "16px",
  //       width: "100%", // Set a consistent width
  //       maxWidth: "800px", // Adjust this value as needed
  //       margin: "0 auto", // Center the box if desired
  //     }}
  //   >
  //     {isLoading && <CircularProgress />}
  //     <Box
  //       sx={{
  //         padding: "20px",
  //         backgroundColor: theme.palette.background.paper,
  //         borderRadius: "8px",
  //         marginBottom: "20px",
  //         minHeight: "20px", // Set a minimum height to prevent collapsing
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "flex-start",
  //           marginBottom: "16px",
  //           gap: "12px",
  //         }}
  //       >
  //         <Button
  //           variant="outlined"
  //           onClick={() => setShowMatchFinder(!showMatchFinder)}
  //           startIcon={<CalendarTodayIcon />}
  //           sx={{
  //             minWidth: "357px", // Wider when collapsed
  //             justifyContent: "flex-start",
  //             padding: "10px 20px",
  //             textTransform: "none",
  //             fontSize: "1rem",
  //             fontWeight: 500,
  //           }}
  //         >
  //           {showMatchFinder ? "Hide Smart Scheduling" : "Smart Scheduling"}
  //         </Button>

  //         <Typography
  //           variant="body2"
  //           color="text.secondary"
  //           sx={{
  //             marginTop: "8px",
  //             textAlign: "left",
  //             marginBottom: "8px",
  //           }}
  //         >
  //           Discover available time slots for multiple people, within a chosen
  //           time frame, and with a maximum number of meetings per person. Our
  //           system will dynamically update the results as availability changes
  //           occur during the specified time period.
  //         </Typography>
  //       </Box>

  //       {showMatchFinder && (
  //         <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
  //           <Box
  //             sx={{
  //               display: "flex",
  //               flexDirection: "column",
  //               gap: "16px",
  //               alignItems: "stretch",
  //             }}
  //           >
  //             <Box
  //               sx={{
  //                 display: "flex",
  //                 gap: "16px",
  //                 width: "100%",
  //                 "& > *": {
  //                   flex: 1,
  //                   minWidth: "200px",
  //                 },
  //               }}
  //             >
  //               <TextField
  //                 type="date"
  //                 label="From Date"
  //                 value={dateRange.from}
  //                 onChange={(e) => {
  //                   setDateRange((prev) => ({ ...prev, from: e.target.value }));
  //                 }}
  //                 InputLabelProps={{ shrink: true }}
  //               />
  //               <TextField
  //                 type="date"
  //                 label="To Date"
  //                 value={dateRange.to}
  //                 onChange={(e) => {
  //                   setDateRange((prev) => ({ ...prev, to: e.target.value }));
  //                 }}
  //                 error={
  //                   dateRange.from &&
  //                   dateRange.to &&
  //                   (new Date(dateRange.to) - new Date(dateRange.from)) /
  //                     (1000 * 60 * 60 * 24) >
  //                     7
  //                 }
  //                 helperText={
  //                   dateRange.from &&
  //                   dateRange.to &&
  //                   (new Date(dateRange.to) - new Date(dateRange.from)) /
  //                     (1000 * 60 * 60 * 24) >
  //                     7
  //                     ? "Date range must not exceed one week"
  //                     : ""
  //                 }
  //                 InputLabelProps={{ shrink: true }}
  //               />
  //             </Box>
  //             <Box
  //               sx={{
  //                 display: "flex",
  //                 gap: "16px",
  //                 width: "100%",
  //                 "& > *": {
  //                   flex: 1,
  //                   minWidth: "200px",
  //                 },
  //               }}
  //             >
  //               <FormControl>
  //                 <InputLabel>Limit matches per person to</InputLabel>
  //                 <Select
  //                   value={maxMeetings}
  //                   label="Limit matches per person to"
  //                   onChange={(e) => setMaxMeetings(e.target.value)}
  //                 >
  //                   {[1, 2, 3, 4, 5].map((num) => (
  //                     <MenuItem key={num} value={num}>
  //                       {num}
  //                     </MenuItem>
  //                   ))}
  //                 </Select>
  //               </FormControl>
  //               <Box /> {/* Empty box to maintain layout */}
  //             </Box>
  //           </Box>

  //           <FormControl>
  //             <Typography
  //               variant="subtitle1"
  //               sx={{ marginBottom: "12px", fontWeight: "medium" }}
  //             >
  //               Select SCPs to Match With
  //             </Typography>
  //             <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
  //               {users
  //                 .filter((user) =>
  //                   [
  //                     28, 30, 31, 33, 34, 39, 40, 41, 42, 43, 44, 46, 47, 50,
  //                     51, 53, 55,
  //                   ].includes(user.id)
  //                 )
  //                 .map((user) => (
  //                   <Chip
  //                     key={user.id}
  //                     label={user.name}
  //                     onClick={() => handleScpSelection(user.id)}
  //                     color={
  //                       selectedScps.includes(user.id) ? "primary" : "default"
  //                     }
  //                     variant={
  //                       selectedScps.includes(user.id) ? "filled" : "outlined"
  //                     }
  //                     sx={{ borderRadius: "16px" }}
  //                   />
  //                 ))}
  //             </Box>
  //           </FormControl>

  //           <Button
  //             variant="contained"
  //             color="primary"
  //             onClick={handleFindMatches}
  //             disabled={isSubmitting || isLoading} // Disable button while submitting/loading
  //           >
  //             {isSubmitting ? "Submitting..." : "Find Matches"}
  //           </Button>
  //         </Box>
  //       )}
  //     </Box>

  //     <Divider sx={{ my: 2 }} />

  //     <Box
  //       sx={{
  //         padding: "20px",
  //         backgroundColor: theme.palette.background.paper,
  //         borderRadius: "8px",
  //         marginBottom: "20px",
  //         minHeight: "20px",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "flex-start",
  //           marginBottom: "16px",
  //           gap: "12px",
  //         }}
  //       >
  //         <Button
  //           variant="outlined"
  //           onClick={() => setShowAvailabilities(!showAvailabilities)}
  //           startIcon={<PersonSearchIcon />}
  //           sx={{
  //             minWidth: "357px",
  //             justifyContent: "flex-start",
  //             padding: "10px 20px",
  //             textTransform: "none",
  //             fontSize: "1rem",
  //             fontWeight: 500,
  //           }}
  //         >
  //           {showAvailabilities
  //             ? "Hide Availability with a Specific Person"
  //             : "Find Availability with a Specific Person"}
  //         </Button>
  //         <Typography
  //           variant="body2"
  //           color="text.secondary"
  //           sx={{
  //             marginTop: "8px",
  //             textAlign: "left",
  //             marginBottom: "8px",
  //           }}
  //         >
  //           Find a time that works for a specific individual.
  //         </Typography>
  //       </Box>

  //       {showAvailabilities && (
  //         <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
  //           <Box
  //             sx={{
  //               display: "flex",
  //               flexDirection: isMobile ? "column" : "row",
  //               alignItems: isMobile ? "stretch" : "center",
  //               gap: "16px",
  //               marginBottom: "16px",
  //             }}
  //           >
  //             <Select
  //               value={timeRange || "nweek"}
  //               onChange={handleTimeRangeChange}
  //               displayEmpty
  //               sx={{
  //                 flex: 1,
  //                 width: "100%",
  //                 minWidth: isMobile ? "100%" : "150px",
  //               }}
  //             >
  //               <MenuItem value="" disabled>
  //                 Select Timeframe
  //               </MenuItem>
  //               <MenuItem value="today">Today</MenuItem>
  //               <MenuItem value="nweek">Coming Week</MenuItem>
  //               <MenuItem value="nmonth">Coming Month</MenuItem>
  //             </Select>
  //             <TextField
  //               select
  //               label="User"
  //               value={selectedUserDetails || ""}
  //               onChange={handleUserChange}
  //               sx={{
  //                 flex: 1,
  //                 width: "100%",
  //                 minWidth: isMobile ? "100%" : "150px",
  //               }}
  //             >
  //               <MenuItem value="" disabled>
  //                 Select User
  //               </MenuItem>
  //               {users.map((user) => (
  //                 <MenuItem
  //                   key={user.id}
  //                   value={user}
  //                   selected={scholar && user.id === lastScpId}
  //                 >
  //                   {user.name}
  //                 </MenuItem>
  //               ))}
  //             </TextField>
  //           </Box>

  //           {availabilities.length > 0 ? (
  //             <TableContainer
  //               component={Paper}
  //               sx={{ marginTop: "20px", overflowX: "auto" }}
  //             >
  //               <Table>
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Date</TableCell>
  //                     <TableCell>Slot</TableCell>
  //                     <TableCell>Actions</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <TableBody>
  //                   {availabilities.map((availability) => (
  //                     <TableRow key={availability.id}>
  //                       <TableCell>
  //                         {formatDate(availability.converted_start_time?.date)}
  //                       </TableCell>
  //                       <TableCell>
  //                         {format(
  //                           parse(
  //                             `${availability.converted_start_time.date} ${availability.converted_start_time.time}`,
  //                             "yyyy-MM-dd HH:mm:ss",
  //                             0
  //                           ),
  //                           "hh:mm aaaa"
  //                         )}{" "}
  //                         -{" "}
  //                         {format(
  //                           parse(
  //                             `${availability.converted_end_time.date} ${availability.converted_end_time.time}`,
  //                             "yyyy-MM-dd HH:mm:ss",
  //                             0
  //                           ),
  //                           "hh:mm aaaa"
  //                         )}
  //                       </TableCell>
  //                       <TableCell>
  //                         <Button
  //                           variant="contained"
  //                           onClick={() =>
  //                             handleBookClick(
  //                               availability,
  //                               `${availability.converted_start_time?.time.slice(
  //                                 0,
  //                                 5
  //                               )}-${availability.converted_end_time?.time.slice(
  //                                 0,
  //                                 5
  //                               )}`
  //                             )
  //                           }
  //                           sx={{ width: isMobile ? "100%" : "auto" }}
  //                         >
  //                           Book
  //                         </Button>
  //                       </TableCell>
  //                     </TableRow>
  //                   ))}
  //                 </TableBody>
  //               </Table>
  //             </TableContainer>
  //           ) : (
  //             <Typography>Please select a user from the dropdown</Typography>
  //           )}

  //           {isLoading ? (
  //             <Typography
  //               style={{
  //                 marginTop: "25px",
  //               }}
  //             >
  //               Loading...
  //             </Typography>
  //           ) : null}

  //           <Dialog open={openDialog} onClose={handleDialogClose}>
  //             <DialogTitle>Select a Time Slot</DialogTitle>
  //             <DialogContent
  //               sx={{
  //                 textAlign: "center",
  //               }}
  //             >
  //               <FormControl component="fieldset">
  //                 <RadioGroup
  //                   aria-label="time-slot"
  //                   name="time-slot-group"
  //                   value={selectedSlot}
  //                   onChange={handleSlotSelection}
  //                 >
  //                   {timeSlots.map((slot, index) => (
  //                     <FormControlLabel
  //                       key={index}
  //                       value={slot}
  //                       control={<Radio />}
  //                       label={slot}
  //                     />
  //                   ))}
  //                 </RadioGroup>
  //               </FormControl>
  //             </DialogContent>
  //             <DialogActions>
  //               <Button
  //                 onClick={handleConfirmBooking}
  //                 variant="contained"
  //                 disabled={!selectedSlot || isSubmitting}
  //               >
  //                 Confirm
  //               </Button>
  //               <Button onClick={handleDialogClose} variant="outlined">
  //                 Cancel
  //               </Button>
  //             </DialogActions>
  //           </Dialog>
  //         </Box>
  //       )}
  //     </Box>

  //     <Snackbar
  //       open={snackbarOpen}
  //       autoHideDuration={6000}
  //       onClose={() => setSnackbarOpen(false)}
  //       message={snackbarMessage}
  //     />
  //   </Box>
  // );

  return (
    <Box
      sx={{
        padding: "16px",
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      {isLoading && <CircularProgress />}
      <Box
        sx={{
          padding: "20px",
          backgroundColor: theme.palette.background.paper,
          borderRadius: "8px",
          marginBottom: "20px",
          minHeight: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "16px",
            gap: "12px",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowMatchFinder(!showMatchFinder)}
            startIcon={<CalendarTodayIcon />}
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              padding: { xs: "10px", sm: "10px 20px" },
              textTransform: "none",
              fontSize: { xs: "0.875rem", sm: "1rem" },
              fontWeight: 500,
            }}
          >
            {showMatchFinder ? "Hide Smart Scheduling" : "Smart Scheduling"}
          </Button>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              marginTop: "8px",
              textAlign: "left",
              marginBottom: "8px",
            }}
          >
            Discover available time slots for multiple people, within a chosen
            time frame, and with a maximum number of meetings per person. Our
            system will dynamically update the results as availability changes
            occur during the specified time period.
          </Typography>
        </Box>

        {showMatchFinder && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "16px",
                width: "100%",
                "& > *": {
                  flex: 1,
                  width: "100%",
                },
              }}
            >
              <TextField
                type="date"
                label="From Date"
                value={dateRange.from}
                onChange={(e) => {
                  setDateRange((prev) => ({ ...prev, from: e.target.value }));
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                type="date"
                label="To Date"
                value={dateRange.to}
                onChange={(e) => {
                  setDateRange((prev) => ({ ...prev, to: e.target.value }));
                }}
                error={
                  dateRange.from &&
                  dateRange.to &&
                  (new Date(dateRange.to) - new Date(dateRange.from)) /
                    (1000 * 60 * 60 * 24) >
                    7
                }
                helperText={
                  dateRange.from &&
                  dateRange.to &&
                  (new Date(dateRange.to) - new Date(dateRange.from)) /
                    (1000 * 60 * 60 * 24) >
                    7
                    ? "Date range must not exceed one week"
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "16px",
                width: "100%",
                "& > *": {
                  flex: 1,
                  width: "100%",
                },
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Limit matches per person to</InputLabel>
                <Select
                  value={maxMeetings}
                  label="Limit matches per person to"
                  onChange={(e) => setMaxMeetings(e.target.value)}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: { xs: "none", sm: "block" } }} />
            </Box>

            <FormControl>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: "12px", fontWeight: "medium" }}
              >
                Select SCPs to Match With
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
                {users
                  .filter((user) =>
                    [
                      28, 30, 31, 33, 34, 39, 40, 41, 42, 43, 44, 46, 47, 50,
                      51, 53, 55,
                    ].includes(user.id)
                  )
                  .map((user) => (
                    <Chip
                      key={user.id}
                      label={user.name}
                      onClick={() => handleScpSelection(user.id)}
                      color={
                        selectedScps.includes(user.id) ? "primary" : "default"
                      }
                      variant={
                        selectedScps.includes(user.id) ? "filled" : "outlined"
                      }
                      sx={{ borderRadius: "16px" }}
                    />
                  ))}
              </Box>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={handleFindMatches}
              disabled={isSubmitting || isLoading}
            >
              {isSubmitting ? "Submitting..." : "Find Matches"}
            </Button>
          </Box>
        )}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box
        sx={{
          padding: "20px",
          backgroundColor: theme.palette.background.paper,
          borderRadius: "8px",
          marginBottom: "20px",
          minHeight: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "16px",
            gap: "12px",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowAvailabilities(!showAvailabilities)}
            startIcon={<PersonSearchIcon />}
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              padding: { xs: "10px", sm: "10px 20px" },
              textTransform: "none",
              fontSize: { xs: "0.875rem", sm: "1rem" },
              fontWeight: 500,
            }}
          >
            {showAvailabilities
              ? "Hide Specific Person Availability"
              : "Find Specific Person Availability"}
          </Button>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              marginTop: "8px",
              textAlign: "left",
              marginBottom: "8px",
            }}
          >
            Find a time that works for a specific individual.
          </Typography>
        </Box>

        {showAvailabilities && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "stretch", sm: "center" },
                gap: "16px",
                marginBottom: "16px",
              }}
            >
              <Select
                value={timeRange || "nweek"}
                onChange={handleTimeRangeChange}
                displayEmpty
                sx={{
                  flex: 1,
                  width: "100%",
                }}
              >
                <MenuItem value="" disabled>
                  Select Timeframe
                </MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="nweek">Coming Week</MenuItem>
                <MenuItem value="nmonth">Coming Month</MenuItem>
              </Select>
              <TextField
                select
                label="User"
                value={selectedUserDetails || ""}
                onChange={handleUserChange}
                sx={{
                  flex: 1,
                  width: "100%",
                }}
              >
                <MenuItem value="" disabled>
                  Select User
                </MenuItem>
                {users.map((user) => (
                  <MenuItem
                    key={user.id}
                    value={user}
                    selected={scholar && user.id === lastScpId}
                  >
                    {user.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {availabilities.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ marginTop: "20px", overflowX: "auto" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Slot</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {availabilities.map((availability) => (
                      <TableRow key={availability.id}>
                        <TableCell>
                          {formatDate(availability.converted_start_time?.date)}
                        </TableCell>
                        <TableCell>
                          {format(
                            parse(
                              `${availability.converted_start_time.date} ${availability.converted_start_time.time}`,
                              "yyyy-MM-dd HH:mm:ss",
                              0
                            ),
                            "hh:mm aaaa"
                          )}{" "}
                          -{" "}
                          {format(
                            parse(
                              `${availability.converted_end_time.date} ${availability.converted_end_time.time}`,
                              "yyyy-MM-dd HH:mm:ss",
                              0
                            ),
                            "hh:mm aaaa"
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleBookClick(
                                availability,
                                `${availability.converted_start_time?.time.slice(
                                  0,
                                  5
                                )}-${availability.converted_end_time?.time.slice(
                                  0,
                                  5
                                )}`
                              )
                            }
                            sx={{ width: { xs: "100%", sm: "auto" } }}
                          >
                            Book
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>Please select a user from the dropdown</Typography>
            )}

            {isLoading ? (
              <Typography
                style={{
                  marginTop: "25px",
                }}
              >
                Loading...
              </Typography>
            ) : null}

            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>Select a Time Slot</DialogTitle>
              <DialogContent
                sx={{
                  textAlign: "center",
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="time-slot"
                    name="time-slot-group"
                    value={selectedSlot}
                    onChange={handleSlotSelection}
                  >
                    {timeSlots.map((slot, index) => (
                      <FormControlLabel
                        key={index}
                        value={slot}
                        control={<Radio />}
                        label={slot}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleConfirmBooking}
                  variant="contained"
                  disabled={!selectedSlot || isSubmitting}
                >
                  Confirm
                </Button>
                <Button onClick={handleDialogClose} variant="outlined">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default SearchOmnibus;
