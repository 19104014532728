import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    console.log("ProtectedRoute mounted");
    const password = prompt("Please enter the password:");
    if (password === process.env.REACT_APP_SECRET_KEY) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    console.log("Waiting for authentication...");
    // Optionally return a loading indicator
    return null;
  }

  if (!isAuthenticated) {
    console.log("Not authenticated, redirecting...");
    return <Navigate to="/" replace />;
  }

  console.log("Authenticated, rendering children");
  return children;
};

export default ProtectedRoute;
