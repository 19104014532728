import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Tooltip,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import MockVideoCall from "../components/MockVideoCall";
import PdfRenderer from "../components/PdfRenderer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function ScreeningCopilot() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [pdfScale, setPdfScale] = useState(1);
  const [displayedContent, setDisplayedContent] = useState("resume");
  const [isResumeLoadedFirstTime, setIsResumeLoadedFirstTime] = useState(false);
  const [finalTableData, setFinalTableData] = useState([]);

  const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes in seconds
  const [keywordMatch, setKeywordMatch] = useState(11);
  const [resumeMatch, setResumeMatch] = useState(9);
  const [profileMatch, setProfileMatch] = useState(10);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [currentSkills, setCurrentSkills] = useState(null);
  const [bboxData, setBboxData] = useState(null);
  const [finalQtDataJSON, setFinalQtData] = useState(null);
  const [jdWriteUp, setJDWriteUp] = useState("");
  const [paramId, setParamId] = useState(null);

  const location = useLocation();
  const threshold = 10;

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  const fetchQtData = async (finalJsonData, jobDescription, id) => {
    const processedJD = await axios.post(
      "https://backend.trayaschedule.hsciglobal.org/process-jd",
      {
        jobDescription: jobDescription,
      }
    );

    const processedClaims = await axios.post(
      "https://backend.trayaschedule.hsciglobal.org/process-resume-claims",
      {
        prompt: JSON.stringify(finalJsonData),
        job_requirements: processedJD.data.message,
      }
    );

    const finalQtData = await axios.post(
      "https://backend.trayaschedule.hsciglobal.org/generate-resume-qts",
      {
        claimsData: JSON.stringify(processedClaims.data.message),
      }
    );

    if (finalQtData)
      localStorage.setItem(
        "postProcessedData-" + id,
        JSON.stringify({
          processedJD: processedJD.data.message,
          processedClaims: processedClaims.data.message.relevantClaims,
          finalQtData: JSON.parse(finalQtData.data.message),
        })
      );
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.id) {
      setParamId(params.id);

      const finalJsonData = JSON.parse(
        localStorage.getItem("finalTableData-" + params.id)
      ).finalJsonData;

      const jobDescription = JSON.parse(
        localStorage.getItem("finalTableData-" + params.id)
      ).jobDescription;
      setBboxData(finalJsonData);
      setJDWriteUp(jobDescription);

      if (!localStorage.getItem("postProcessedData-" + params.id))
        fetchQtData(finalJsonData, jobDescription, params.id);
      else {
        const finalQtData = JSON.parse(
          localStorage.getItem("postProcessedData-" + params.id)
        ).finalQtData;
        setFinalQtData(finalQtData);
      }
    }
  }, []);

  const handleOverlayClick = (e, item) => {
    e.preventDefault();
    console.log("item: ", item);
    setCurrentSkills(item.skills);

    if (item.isHighlighted) {
      setCurrentQuestionIndex(item.claimId);
      setShowFollowUp(false);
      setSelectedOption(null);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOptionClick = (index) => {
    console.log("index: ", index);
    setSelectedOption(index);
    if (index === 0) {
      setResumeMatch((prev) => Math.min(prev + 1, 15));
      setProfileMatch((prev) => Math.min(prev + 1, 15));
      setKeywordMatch((prev) => Math.min(prev + 1, 15));
    } else if (index === 1) {
      setResumeMatch((prev) => Math.min(prev + 0.5, 15));
      setProfileMatch((prev) => Math.min(prev + 0.5, 15));
      setKeywordMatch((prev) => Math.min(prev + 0.5, 15));
    } else {
      setResumeMatch((prev) => Math.min(prev - 1, 15));
      setProfileMatch((prev) => Math.min(prev - 1, 15));
      setKeywordMatch((prev) => Math.min(prev - 1, 15));
    }
  };

  const handleZoomIn = () => {
    setPdfScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setPdfScale((prevScale) => Math.max(1, prevScale - 0.1)); // Ensure scale doesn't go below 0.5
  };

  const handleToggleChange = (event, newContent) => {
    if (newContent !== null) {
      setDisplayedContent(newContent);
      // Set the resume loaded flag only the first time
      if (newContent === "resume" && !isResumeLoadedFirstTime) {
        setIsResumeLoadedFirstTime(true);
      }
    }
  };

  const getCandidateFitColor = (score) => {
    if (score >= threshold) {
      return "green";
    } else if (score >= threshold - 2) {
      return "orange";
    } else {
      return "red";
    }
  };

  const sectionsWithBoxes = [
    "Responsibilities:",
    "Required skills and qualifications:",
    "Preferred skills and qualifications:",
  ];

  return (
    <Grid container spacing={0} style={{ height: "100vh" }}>
      {/* Left Section (Resume or Job Description) */}
      <Grid
        item
        xs={5}
        style={{
          height: "100%",
          overflow: "hidden",
          paddingRight: "15px",
        }}
      >
        <Paper
          elevation={3}
          style={{
            position: "relative",
            height: "100%",
            boxShadow: "inset -10px 0 10px -10px rgba(0, 0, 0, 0.1)",
            overflow: "auto", // Enable scrolling
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100,
              backgroundColor: "white",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <ToggleButtonGroup
              value={displayedContent}
              exclusive
              onChange={handleToggleChange}
              aria-label="display toggle"
            >
              <ToggleButton
                value="resume"
                aria-label="resume"
                style={{
                  padding: "10px 20px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Resume
              </ToggleButton>
              <ToggleButton
                value="jobDescription"
                aria-label="job description"
                style={{
                  padding: "10px 20px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Job Description
              </ToggleButton>
            </ToggleButtonGroup>
            {displayedContent === "resume" && (
              <div>
                <IconButton onClick={handleZoomIn}>
                  <ZoomInIcon />
                </IconButton>
                <IconButton onClick={handleZoomOut}>
                  <ZoomOutIcon />
                </IconButton>
              </div>
            )}
          </Box>

          {displayedContent === "resume" && (
            <div style={{ position: "relative", marginTop: "60px" }}>
              <PdfRenderer
                scale={pdfScale}
                shouldRender={!isResumeLoadedFirstTime}
                handleOverlayClick={handleOverlayClick}
                currentSkills={currentSkills}
                bboxData={bboxData}
                finalQtDataJSON={finalQtDataJSON}
                paramId={paramId}
              />
            </div>
          )}
          {displayedContent === "jobDescription" && (
            <div
              style={{
                position: "relative",
                padding: "20px",
                paddingRight: "50px",
                marginTop: "60px",
                whiteSpace: "pre-wrap",
              }}
            >
              <Typography
                variant="body1"
                style={{ lineHeight: "1.6", fontSize: "14px" }}
              >
                {jdWriteUp.split("\n").map((line, index) => {
                  // Add a line break if the line is empty
                  if (line.trim() === "") {
                    return <br key={index} />;
                  }

                  // Check if the line is one of the section headers
                  if (sectionsWithBoxes.includes(line.trim())) {
                    return (
                      <div key={index} style={{ margin: "10px 0" }}>
                        <strong>{line}</strong>
                      </div>
                    );
                  }

                  // Add overlay boxes for bullet points
                  if (line.startsWith("-")) {
                    return (
                      <Tooltip title={line.trim()} arrow key={index}>
                        <div
                          style={{
                            display: "block",
                            textAlign: "left",
                            margin: "5px 0",
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100%",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", // Adding slight shadow for depth
                            border: "1px solid orange",
                            cursor: "pointer",
                            backgroundColor: "rgba(255, 165, 0, 0.3)",
                          }}
                        >
                          {line.trim()}
                        </div>
                      </Tooltip>
                    );
                  }

                  return <div key={index}>{line}</div>;
                })}
              </Typography>
            </div>
          )}
        </Paper>
      </Grid>

      {/* Middle Section (Timer, MCQ, Candidate Fit) */}
      <Grid
        item
        xs={2}
        style={{
          height: "100%",
          padding: "10px",
          backgroundColor: "#f0f4f8",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Timer */}
        <Box style={{ marginBottom: "10px", flexShrink: 0 }}>
          <Paper
            elevation={3}
            style={{
              padding: "15px",
              background: "linear-gradient(135deg, #1a237e, #3949ab)",
              color: "#fff",
              borderRadius: "16px",
              border: "1px solid #3f51b5",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <AccessTimeIcon style={{ fontSize: "36px" }} />
            <Typography
              variant="h4"
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "36px",
                letterSpacing: "1px",
              }}
            >
              {formatTime(timeLeft)}
            </Typography>
          </Paper>
        </Box>

        {/* Scrollable container for MCQ and Candidate Fit */}
        <Box
          style={{
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* MCQ Section */}
          <Paper
            elevation={3}
            style={{
              padding: "15px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              height: "auto",
            }}
          >
            {currentQuestionIndex && finalQtDataJSON ? (
              <>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    fontSize: "14px",
                    overflowWrap: "break-word", // Allow text to wrap
                    wordBreak: "break-word", // Break long words if necessary
                    textAlign: "center",
                    lineHeight: "1",
                  }}
                >
                  {showFollowUp
                    ? finalQtDataJSON.find(
                        (item) => item.claimId === currentQuestionIndex
                      )?.followUpQuestion
                    : finalQtDataJSON.find(
                        (item) => item.claimId === currentQuestionIndex
                      )?.question}
                </Typography>
                <Box>
                  {Object.values(
                    showFollowUp
                      ? finalQtDataJSON.find(
                          (item) => item.claimId === currentQuestionIndex
                        )?.followUpAnswers
                      : finalQtDataJSON.find(
                          (item) => item.claimId === currentQuestionIndex
                        )?.answers
                  ).map((option, index) => (
                    <Button
                      key={index}
                      onClick={() => handleOptionClick(index)}
                      variant={
                        selectedOption === index ? "contained" : "outlined"
                      }
                      style={{
                        display: "block",
                        margin: "10px 0",
                        textTransform: "none",
                        width: "100%",
                        backgroundColor:
                          selectedOption === index ? "grey" : "#fff",
                        color: selectedOption === index ? "#000" : "#000",
                        borderRadius: "5px",
                        fontSize: "12px",
                        textAlign: "left", // Align text to the left
                        whiteSpace: "normal", // Allow text to wrap
                        height: "auto", // Allow height to adjust to content
                        padding: "8px 12px", // Consistent padding
                        overflowWrap: "break-word", // Allow text to wrap
                        wordBreak: "break-word", // Break long words if necessary
                        textAlign: "center",
                        lineHeight: "1.2",
                      }}
                    >
                      {option}
                    </Button>
                  ))}
                </Box>
                {selectedOption !== null && !showFollowUp && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowFollowUp(true);
                      setSelectedOption(null);
                    }}
                    style={{ marginTop: "20px" }}
                  >
                    Follow Up
                  </Button>
                )}
              </>
            ) : (
              <Typography
                variant="body2"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  margin: "25px",
                }}
              >
                Click on an overlay in the resume to ask questions!
              </Typography>
            )}
          </Paper>
        </Box>

        {/* Candidate Fit Section */}
        <Paper
          elevation={3}
          style={{
            padding: "15px",
            backgroundColor: getCandidateFitColor(keywordMatch),
            borderRadius: "10px",
            color: "#fff",
            marginTop: "15px",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            Candidate Fit
          </Typography>
          <Box style={{ marginTop: "10px" }}>
            <Typography
              variant="body1"
              style={{ marginBottom: "5px", textAlign: "center" }}
            >
              Keyword Match {keywordMatch}/15
            </Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: "5px", textAlign: "center" }}
            >
              Resume Match {resumeMatch}/15
            </Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: "5px", textAlign: "center" }}
            >
              Profile Match {profileMatch}/15
            </Typography>
          </Box>
        </Paper>
      </Grid>

      <Grid
        item
        xs={5}
        style={{
          height: "100%",
          paddingLeft: "15px",
        }}
      >
        <Paper
          elevation={3}
          style={{
            height: "100%",
            boxShadow: "inset 10px 0 10px -10px rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        >
          <MockVideoCall />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ScreeningCopilot;
