import React, { useRef, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
// import VideoScreenMock from "../assets/InterviewScreen.mp4";
// import RecordIcon from "../assets/recGif.gif";

const MockVideoCall = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const getVideoStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing webcam: ", err);
      }
    };

    getVideoStream();

    return () => {
      // Stop the video stream when the component is unmounted
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {/* Your Webcam Video */}
      <Box
        style={{
          flex: 1,
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          muted
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          <Tooltip title="Screen Record" arrow>
            {/* <img src={RecordIcon} style={{ width: "50px", height: "35px" }} /> */}
          </Tooltip>
        </Box>
      </Box>

      {/* Other Participant's Video (Mock with an MP4) */}
      <Box
        style={{
          flex: 1,
          backgroundColor: "lightgray",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          paddingBottom: "10px", // Margin at the bottom
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            width: "100%",
            height: "auto", // Maintain aspect ratio
            maxHeight: "100%", // Ensure it doesn't overflow the container
          }}
        >
          {/* <source src={VideoScreenMock} type="video/mp4" /> */}
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
};

export default MockVideoCall;
