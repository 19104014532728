import React, { useEffect, useRef, useState } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Popper,
  Fade,
  Paper,
  Typography,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PdfRenderer = ({
  scale,
  shouldRender,
  handleOverlayClick,
  currentSkills,
  bboxData,
  finalQtDataJSON,
  paramId,
}) => {
  const canvasRef = useRef(null);
  const [isRendering, setIsRendering] = useState(false);
  const [renderedPage, setRenderedPage] = useState(null);
  const [pdfDimensions, setPdfDimensions] = useState({ width: 0, height: 0 });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowDirection, setArrowDirection] = useState("bottom");
  const [renderTask, setRenderTask] = useState(null);
  const [claimIds, setClaimIds] = useState([]); // Manage claimIds as state
  const [bboxHighlights, setBboxHighlights] = useState([]); // Store the highlighted bboxes

  const cancelRenderTask = () => {
    if (renderTask) {
      renderTask.cancel();
      setRenderTask(null);
    }
  };

  // Detect changes in `finalQtDataJSON` and update claimIds
  useEffect(() => {
    if (finalQtDataJSON) {
      const claimIdsTemp = finalQtDataJSON.map((claim) => claim.claimId);
      setClaimIds(claimIdsTemp); // Set claimIds to trigger re-render
    }
  }, [finalQtDataJSON]);

  const setOverlayData = () => {
    const highlightedBoxes = bboxData.map((item) => ({
      ...item,
      isHighlighted: claimIds.includes(item.claimId) || false,
    }));
    setBboxHighlights(highlightedBoxes); // Set highlighted bounding boxes
  };

  // Monitor `claimIds` and bboxData and recalculate highlights
  useEffect(() => {
    if (bboxData) {
      // setOverlayData();
      const highlightedBoxes = bboxData.map((item) => ({
        ...item,
        isHighlighted: false,
      }));
      setBboxHighlights(highlightedBoxes); // Set highlighted bounding boxes
    }
  }, [bboxData]);

  useEffect(() => {
    console.log("IN CLAIMID USEEFFECT");
    if (claimIds.length > 0 && bboxData) {
      console.log("CLAIMIDS ARE GENERATED");
      // setOverlayData();
      const highlightedBoxes = bboxData.map((item) => ({
        ...item,
        isHighlighted: claimIds.includes(item.claimId),
      }));
      setBboxHighlights(highlightedBoxes); // Set highlighted bounding boxes
    }
  }, [claimIds, bboxData]);

  const renderPDF = async () => {
    setIsRendering(true);

    // Retrieve PDF file
    let file = JSON.parse(
      localStorage.getItem("finalTableData-" + paramId)
    ).resumePath;
    const loadingTask = getDocument(file);
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(1);

    // Ensure consistent viewport with scaling
    const viewport = page.getViewport({ scale });

    // Ensure canvas is correctly cleared and reset dimensions
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Reset the canvas dimensions
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    context.clearRect(0, 0, canvas.width, canvas.height); // Clear before render

    setPdfDimensions({ width: viewport.width, height: viewport.height });

    const renderContext = {
      canvasContext: context,
      viewport: page.getViewport({ scale }), // Ensure viewport uses the correct scale
    };

    cancelRenderTask(); // Cancel any ongoing render task before starting a new one

    const task = page.render(renderContext);
    setRenderTask(task);

    try {
      await task.promise;
      setRenderedPage({ page, viewport }); // Store the rendered page and viewport
    } catch (error) {
      if (error.name !== "RenderingCancelledException") {
        console.error("Rendering failed:", error);
      }
    } finally {
      setIsRendering(false);
    }
  };

  useEffect(() => {
    if (paramId) {
      if (!renderedPage) {
        renderPDF(); // Render on initial load
      } else if (scale !== renderedPage?.viewport.scale && shouldRender) {
        renderPDF(); // Re-render on scale change
      }
      return () => cancelRenderTask();
    }
  }, [scale, renderedPage, shouldRender, paramId]);

  const getScalingFactor = (pdfWidth, pdfHeight, ocrWidth, ocrHeight) => {
    const scaleX = pdfWidth / ocrWidth;
    const scaleY = pdfHeight / ocrHeight;
    return { scaleX, scaleY };
  };

  const handleOverlayClickWithSkills = (e, item) => {
    handleOverlayClick(e, item);
    const rect = e.target.getBoundingClientRect();

    const windowHeight = window.innerHeight;
    const isInUpperHalf = rect.top < windowHeight / 2;

    setArrowDirection(isInUpperHalf ? "bottom" : "top");

    setDialogPosition({
      top: isInUpperHalf ? rect.bottom : rect.top,
      left: rect.left,
    });
    setAnchorEl(e.currentTarget);
    if (currentSkills) setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAnchorEl(null);
  };

  return (
    <>
      {isRendering && paramId && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div style={{ position: "relative" }}>
        <canvas
          ref={canvasRef}
          style={{ display: isRendering ? "none" : "block", margin: "auto" }}
        />
        {!isRendering &&
          bboxHighlights.length > 0 &&
          bboxHighlights.map((item, index) => {
            if (!item.bounding_box) return null;

            const { scaleX, scaleY } = getScalingFactor(
              pdfDimensions.width,
              pdfDimensions.height,
              JSON.parse(localStorage.getItem("finalTableData-" + paramId))
                .image_width,
              JSON.parse(localStorage.getItem("finalTableData-" + paramId))
                .image_height
            );

            const scaledBBox = {
              x_min: item.bounding_box.x_min * scaleX,
              y_min: item.bounding_box.y_min * scaleY,
              x_max: item.bounding_box.x_max * scaleX,
              y_max: item.bounding_box.y_max * scaleY,
            };

            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: scaledBBox.y_min,
                  left: scaledBBox.x_min,
                  width: scaledBBox.x_max - scaledBBox.x_min,
                  height: scaledBBox.y_max - scaledBBox.y_min,
                  border: item.isHighlighted
                    ? "2px solid rgba(100, 200, 100, 0.8)"
                    : "2px solid rgba(255, 152, 0, 0.4)",
                  borderRadius: "3px",
                  cursor: "pointer",
                  transition: "border 0.3s ease, background-color 0.3s ease",
                  backgroundColor: "rgba(255, 152, 0, 0.05)",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.border = item.isHighlighted
                    ? "2px solid rgba(100, 200, 100, 0.8)"
                    : "2px solid #ff9800";
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 152, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.border = item.isHighlighted
                    ? "2px solid rgba(100, 200, 100, 0.8)"
                    : "2px solid rgba(255, 152, 0, 0.4)";
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 152, 0, 0.05)";
                }}
                onClick={(e) => handleOverlayClickWithSkills(e, item)}
              />
            );
          })}
      </div>

      <Popper
        open={openDialog}
        anchorEl={anchorEl}
        placement={arrowDirection === "bottom" ? "bottom-start" : "top-start"}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              style={{
                padding: "16px",
                borderRadius: "8px",
                boxShadow: "0 3px 5px rgba(0,0,0,0.2)",
                position: "relative",
                backgroundColor: "#fff",
              }}
            >
              {/* Close Button */}
              <IconButton
                onClick={handleCloseDialog}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  padding: "4px",
                }}
              >
                <CloseIcon />
              </IconButton>

              {/* Arrow based on position */}
              {arrowDirection === "bottom" ? (
                <ArrowDropUpIcon
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "10px",
                    color: "#fff",
                    fontSize: "2rem",
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    left: "10px",
                    color: "#fff",
                    fontSize: "2rem",
                  }}
                />
              )}

              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  marginBottom: "8px",
                  color: "#333",
                }}
              >
                Skills
              </Typography>
              {currentSkills && currentSkills.length > 0 ? (
                <List>
                  {currentSkills.map((skill, index) => (
                    <ListItem
                      key={index}
                      style={{
                        padding: "6px 12px",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        columnGap: "10px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {skill.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "0.9rem",
                          color: "#666",
                          backgroundColor: "#fff",
                          padding: "4px 8px",
                          borderRadius: "5px",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {skill.skill}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No skills available.</Typography>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default PdfRenderer;
