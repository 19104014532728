import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAllAppointmentsForUser } from "../controllers/appointment";
import moment from "moment-timezone";

const Meetings = ({ user }) => {
  const [meetingsData, setMeetingsData] = useState({ past: [], upcoming: [] });

  useEffect(() => {
    getAllAppointmentsForUser(user.id).then((res) => {
      const now = moment.utc();

      const formatMeeting = (meeting) => {
        const meetingDateUTC = moment.utc(
          `${meeting.meeting_date}T${meeting.meeting_time.split("-")[0]}`
        );
        const meetingDateUserTZ = meetingDateUTC.clone().tz(user.timezone);
        const formattedDate = meetingDateUserTZ.format("D MMMM YYYY");
        const formattedTime = meetingDateUserTZ.format("h:mm A");
        const participants = [
          meeting.patient?.name
            ? meeting.patient.name
            : meeting.student?.name
            ? meeting.student.name
            : "",
          meeting.scp.name,
        ];
        const meeting_link =
          meeting.scp_id === user.id
            ? Object.keys(meeting.meeting_link_patient)[0] + "&role=s"
            : Object.keys(meeting.meeting_link_scp)[0] + "&role=p";

        return {
          date: formattedDate,
          time: formattedTime,
          participants,
          meetingDateUTC,
          scp_id: meeting.scp_id,
          patient_id: meeting.patient_id,
          meeting_link: meeting_link,
        };
      };

      const transformedData = res.reduce(
        (acc, meeting) => {
          const meetingDateUTC = moment.utc(
            `${meeting.meeting_date}T${meeting.meeting_time.split("-")[0]}`
          );
          const meetingDateUserTZ = meetingDateUTC.clone().tz(user.timezone);

          if (meetingDateUserTZ.isBefore(now)) {
            acc.past.push(formatMeeting(meeting));
          } else {
            acc.upcoming.push(formatMeeting(meeting));
          }

          return acc;
        },
        { past: [], upcoming: [] }
      );

      setMeetingsData(transformedData);
    });
  }, [user.timezone]);

  const { past, upcoming } = meetingsData;

  const generateGoogleCalendarLink = (meeting) => {
    const start = meeting.meetingDateUTC.format("YYYYMMDDTHHmmss[Z]");
    const end = meeting.meetingDateUTC
      .clone()
      .add(1, "hour")
      .format("YYYYMMDDTHHmmss[Z]");

    const details = `Participants: ${meeting.participants.join(", ")}`;
    const location =
      "\nMeeting Link: http://trayaschedule.hsciglobal.org/meeting?" +
      meeting.meeting_link;
    const text = "Meeting with " + meeting.participants.join(" and ");

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}%2F${end}&details=${encodeURIComponent(
      details + location
    )}&text=${encodeURIComponent(text)}`;
  };

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontFamily: "Arial, sans-serif" }}>
            Upcoming Meetings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {upcoming.map((meeting, index) => (
              <div key={index}>
                <ListItem>
                  <ListItemText
                    primary={`${meeting.date} at ${meeting.time}`}
                    secondary={`Participants: ${meeting.participants.join(
                      ", "
                    )}`}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                    <IconButton
                      color="primary"
                      href={generateGoogleCalendarLink(meeting)}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ padding: "6px" }} // Smaller padding for a more compact button
                    >
                      <EventIcon />
                    </IconButton>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        ml: 1,
                        fontSize: "0.875rem",
                        textDecoration: "none",
                      }}
                      component="a"
                      href={generateGoogleCalendarLink(meeting)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Add to Google Calendar
                    </Typography>
                  </Box>
                </ListItem>
                {index < upcoming.length - 1 && <Divider />}
              </div>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontFamily: "Arial, sans-serif" }}>
            Past Meetings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {past.map((meeting, index) => (
              <div key={index}>
                <ListItem>
                  <ListItemText
                    primary={`${meeting.date} at ${meeting.time}`}
                    secondary={`Participants: ${meeting.participants.join(
                      ", "
                    )}`}
                  />
                </ListItem>
                {index < past.length - 1 && <Divider />}
              </div>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Meetings;
