import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../utils/supabase";
import { getStudentByPhone } from "../controllers/students";
import BookKCAvailability from "../components/BookKCAvailability";
import queryString from "query-string";
import { Box, Typography, CircularProgress } from "@mui/material";

const Booking = () => {
  const [user, setUser] = useState(null);

  const location = useLocation();

  const verifyUser = async () => {
    const params = queryString.parse(location.search);

    if (params.id) {
      const columnName = "preferences_link";
      const { data, error } = await supabase
        .from("students")
        .select(`id, name, ${columnName}`);

      if (error) {
        console.error("Error fetching data from Supabase:", error);
      } else if (data && data.length > 0) {
        // Find the row where the JSON column contains the key
        const row = data.find((row) => row[columnName][params.id]);

        if (row) {
          getStudentByPhone(row[columnName][params.id]).then((res) => {
            if (res) {
              setUser(res);
            }
          });
        } else {
          console.error("Key not found in any row's JSON object");
        }
      }
    }
  };

  useEffect(() => {
    verifyUser();
  }, [location, setUser]);

  return user ? (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#f0f4f8",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        maxWidth: "600px",
        margin: "auto",
        marginTop: "20px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Welcome, {user.name} ji
      </Typography>
      <BookKCAvailability student={user} />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f4f8",
      }}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ marginLeft: "16px" }}>
        Loading...
      </Typography>
    </Box>
  );
};

export default Booking;
