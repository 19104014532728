import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getAppointmentById } from "../controllers/appointment";
import { format, toZonedTime } from "date-fns-tz";

const MeetingFeedback = () => {
  const location = useLocation();

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  };

  const messageStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "1.5em",
    color: "#333",
    marginTop: "20px",
  };

  const triggerFeedback = async (data) => {
    try {
      const response = await axios.post(
        "https://backend.trayaschedule.hsciglobal.org/feedback-loop",
        data
      );
      console.log("Response from server:", response.data);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const getAppointmentDetailsById = async (appointmentId, role) => {
    const res = await getAppointmentById(appointmentId);

    let userTimezone =
      role === "p"
        ? res.patient.timezone
        : role === "st"
        ? res.student.timezone
        : res.scp.timezone;
    let meetingDate = res.meeting_date;
    let meetingStart = res.meeting_time.split("-")[0];
    let meetingEnd = res.meeting_time.split("-")[1];

    // Extract year, month, day, hours, and minutes from the date and time strings
    const [year, month, day] = meetingDate.split("-").map(Number);
    const [startHour, startMinute] = meetingStart.split(":").map(Number);
    const [endHour, endMinute] = meetingEnd.split(":").map(Number);

    // Create Date objects in UTC
    const startDateTimeUTC = new Date(
      Date.UTC(year, month - 1, day, startHour, startMinute)
    );

    const endDateTimeUTC = new Date(
      Date.UTC(year, month - 1, day, endHour, endMinute)
    );

    // Convert the UTC date-time to the desired timezone
    const startDateTimeInTZ = toZonedTime(startDateTimeUTC, userTimezone);
    const endDateTimeInTZ = toZonedTime(endDateTimeUTC, userTimezone);

    // Format the date and time in the desired format
    const formattedDate = format(startDateTimeInTZ, "yyyy-MM-dd");
    const formattedStart = format(startDateTimeInTZ, "HH:mm");
    const formattedEnd = format(endDateTimeInTZ, "HH:mm");

    let matchedName;
    if (role === "s") {
      matchedName = res.student ? res.student.name : res.patient.name;
    }

    let dataJson = {
      name:
        role === "p"
          ? res.patient.name
          : role === "st"
          ? res.student.name
          : res.scp.name,
      whatsapp_phone_no:
        role === "p"
          ? res.patient_phone
          : role === "st"
          ? res.patient_phone
          : res.scp_phone,
      appointment_id: res.id,
      role_type: role,
      matched_name:
        role === "p"
          ? res.scp.name
          : role === "st"
          ? res.scp.name
          : matchedName,
      meeting_date: formatDate(formattedDate, userTimezone),
      meeting_time:
        formatTime(formattedStart) +
        "-" +
        formatTime(formattedEnd) +
        " (" +
        userTimezone +
        ")",
    };

    await triggerFeedback(dataJson);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(hours, minutes);

    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatDate = (dateString, timeZone) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone,
    };
    const date = new Date(dateString + "T00:00:00"); // Ensure the time is set to midnight to avoid timezone shifts
    return new Intl.DateTimeFormat("en-GB", options).format(date); // 'en-GB' uses day-month-year order
  };

  const hasRun = useRef(false);

  useEffect(() => {
    if (location && !hasRun.current) {
      hasRun.current = true;
      const params = new URLSearchParams(location.search);
      const appointmentId = params.get("appt");
      const role = params.get("role");

      getAppointmentDetailsById(appointmentId, role);
    }
  }, [location]);

  return (
    <div style={containerStyle}>
      <div>
        <div style={messageStyle}>
          We hope you had a productive session. We will reach out to you on
          WhatsApp shortly to gather your feedback.
        </div>
      </div>
    </div>
  );
};

export default MeetingFeedback;
