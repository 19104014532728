import React, { useState, useEffect } from "react";
import {
  getScpAvailabilityToday,
  getScpAvailabilityThisWeek,
  getScpAvailabilityThisMonth,
} from "../../controllers/analytics";
import {
  TextField,
  MenuItem,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getAllSCPs } from "../../controllers/scp";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const SearchSCPAvailability = () => {
  const [timeRange, setTimeRange] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [availabilities, setAvailabilities] = useState([]);
  const [users, setUsers] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getAllSCPs().then((res) => {
      const usersArray = res.map((user) => ({
        id: user.id,
        name: user.name,
      }));
      setUsers(usersArray);
    });
  }, []);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const fetchAvailability = async () => {
    let data = [];
    if (!timeRange || !selectedUser) {
      alert("Please select both timeframe and user.");
      return;
    }

    switch (timeRange) {
      case "today":
        data = await getScpAvailabilityToday(selectedUser);
        break;
      case "week":
        data = await getScpAvailabilityThisWeek(selectedUser);
        break;
      case "month":
        data = await getScpAvailabilityThisMonth(selectedUser);
        break;
      default:
        break;
    }

    // Sort the data by date in ascending order
    data.sort((a, b) => new Date(a.date) - new Date(b.date));
    setAvailabilities(data);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy");
  };

  const formatTime = (timeString, dateString, timezone) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date(dateString);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    return formatInTimeZone(date, timezone, "hh:mm a");
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <p>SCP SCHEDULE</p>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "stretch" : "center",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        <Select
          value={timeRange}
          onChange={handleTimeRangeChange}
          displayEmpty
          sx={{ width: isMobile ? "100%" : "150px" }}
        >
          <MenuItem value="" disabled>
            Select Timeframe
          </MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="week">This Week</MenuItem>
          <MenuItem value="month">This Month</MenuItem>
        </Select>
        <TextField
          select
          label="User"
          value={selectedUser}
          onChange={handleUserChange}
          sx={{ width: isMobile ? "100%" : "150px" }}
        >
          <MenuItem value="" disabled>
            Select User
          </MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          onClick={fetchAvailability}
          sx={{ width: isMobile ? "100%" : "auto" }}
        >
          Get Availability
        </Button>
      </Box>
      {availabilities.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", overflowX: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Slot</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availabilities.map((availability) => (
                <TableRow key={availability.id}>
                  <TableCell>{formatDate(availability.date)}</TableCell>
                  <TableCell>{`${formatTime(
                    availability.start_time,
                    availability.date,
                    availability.scp.timezone
                  )} - ${formatTime(
                    availability.end_time,
                    availability.date,
                    availability.scp.timezone
                  )} (${availability.scp.timezone})`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default SearchSCPAvailability;
